/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
import { unwrapResult } from "@reduxjs/toolkit";
import { Checkbox, Collapse, Input, Modal, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import React, { useEffect, useRef, useState } from "react";
import { IoIosArrowForward, IoIosSearch } from "react-icons/io";
import { IoArrowBackCircleOutline } from "react-icons/io5";
import { BiSolidSelectMultiple } from "react-icons/bi";
import { MdDeselect } from "react-icons/md";
import {
  setLoading as setOrderLoading,
  setOrders,
} from "../../../../../store/draftSlice/createDraftSlice";

import {
  getAllCollections,
  getAllProducts,
  getAllTags,
  getAllTypes,
  getCategories,
  getProductByCollection,
  getProductByType,
  getVariantsById,
  ProductDataType,
  ProductType,
  searchAllClientProducts,
  searchAllCollections,
  searchAllProducts,
  searchProductByCollection,
  Variant,
} from "../../../../../store/ordersSlice/ordersProductSlice";
import { useAppDispatch, useAppSelector } from "../../../../../store/store";
import { CheckboxValueType } from "antd/lib/checkbox/Group";
import { union } from "lodash";
import { createOrder, editOrder } from "../../../../../store/draftSlice/draftSlice";
import {
  Address,
  CustomerDetailsDataType,
} from "../../../../../store/customersSlice/customersSlice";
import { draft } from "../../../../../interfaces/Drafts";

export const ProductModal = ({
  open,
  setOpen,
  search,
  setSearch,
  Customer,
  billingAddress,
  shippingAddress,
}: {
  open: boolean;
  setOpen: Function;
  search: string;
  setSearch: Function;
  Customer?: CustomerDetailsDataType;
  billingAddress?: Address;
  shippingAddress?: Address;
}) => {
  enum title {
    root = "Select Products",
    all = "All Products",
    collections = "Collections",
    types = "Product types",
    tags = "Tags",
    clientProducts = "Products By Client Collection",
  }

  const { Panel } = Collapse;

  const input = useRef<any>(null);
  const [value, setValue] = useState("");
  const [header, setTitle] = useState<string[]>([title.root]);
  const [categories, setCategories] = useState<string[]>([]);
  const [filterId, setFilterId] = useState<number>();
  const [filters, setFilters] = useState<{ title: string; id: number }[]>([]);
  const [products, setProducts] = useState<ProductType[]>([]);
  const [variants, setVariants] = useState<Variant[]>([]);
  const [selectedProducts, setSelectedProducts] = useState<CheckboxValueType[]>([]);
  const [discounts, setDiscounts] = useState<{ id: number; value: number }[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [activeKey, setActiveKey] = useState<number>();
  const [variantLoading, setVariantLoading] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const orders = useAppSelector((state) => state.newdraft.orders);
  const customer = useAppSelector((state) => state.newdraft.customer);
  const note = useAppSelector((state) => state.newdraft.note);

  useEffect(() => {
    dispatch(getCategories())
      .then(unwrapResult)
      .then((res) => {
        setCategories(res.data);
      });
  }, []);

  // useEffect(() => {
  //   console.log({ discounts });
  // }, [discounts]);

  useEffect(() => {
    if (open) {
      setSelectedProducts(
        orders?.line_items?.map((y) => `${y.variant_id},${parseInt(y.price)}`) ?? []
      );
    }
  }, [open, orders]);

  // useEffect(() => {
  //   console.log({ selectedProducts });
  // }, [selectedProducts]);

  useEffect(() => {
    if (open) input?.current?.focus();

    setValue(search);
  }, [open, search]);

  useEffect(() => {
    header[header.length - 1] === title.root &&
      value.length > 0 &&
      setTitle((old) => [...old, categories[0]]);
  }, [value]);

  useEffect(() => {
    header[header.length - 1] !== title.all && setValue("");
  }, [header]);

  useEffect(() => {
    setLoading(true);
    console.log({ header, value });
    let getData: NodeJS.Timeout;
    try {
      switch (header[header.length - 1]) {
        case title.all:
          value.length === 0
            ? dispatch(searchAllProducts({ query: "" }))
                .then(unwrapResult)
                .then((res) => {
                  setProducts(res.data);
                  setLoading(false);
                })
            : (getData = setTimeout(() => {
                dispatch(searchAllProducts({ query: value }))
                  .then(unwrapResult)
                  .then((res) => {
                    setProducts(res.data);
                    setLoading(false);
                  });
              }, 300));

          break;
        case title.clientProducts:
          value.length === 0
            ? dispatch(searchAllClientProducts({ query: "", customerId: customer?.id ?? 0 }))
                .then(unwrapResult)
                .then((res) => {
                  setProducts(res.data);
                  setLoading(false);
                })
            : (getData = setTimeout(() => {
                dispatch(searchAllClientProducts({ query: value, customerId: customer?.id ?? 0 }))
                  .then(unwrapResult)
                  .then((res) => {
                    setProducts(res.data);
                    setLoading(false);
                  });
              }, 300));

          break;
        case title.collections:
          value.length === 0
            ? dispatch(getAllCollections())
                .then(unwrapResult)
                .then((res) => {
                  setFilters([]);
                  res.data.map((x) => setFilters((old) => [...old, { title: x.title, id: x.id }]));
                  setLoading(false);
                })
            : (getData = setTimeout(() => {
                dispatch(searchAllCollections({ query: value }))
                  .then(unwrapResult)
                  .then((res) => {
                    setFilters([]);
                    res.data.map((x) =>
                      setFilters((old) => [...old, { title: x.title, id: x.id }])
                    );
                    setLoading(false);
                  });
              }, 300));
          break;

        case title.types:
          value.length === 0
            ? dispatch(getAllTypes({ query: "" }))
                .then(unwrapResult)
                .then((res) => {
                  setFilters([]);
                  res.data.map((x) => setFilters((old) => [...old, { title: x.value, id: x.id }]));
                  setLoading(false);
                  setFilterId(undefined);
                })
            : (getData = setTimeout(() => {
                dispatch(getAllTypes({ query: value }))
                  .then(unwrapResult)
                  .then((res) => {
                    setFilters([]);
                    res.data.map((x) =>
                      setFilters((old) => [...old, { title: x.value, id: x.id }])
                    );
                    setLoading(false);
                    setFilterId(undefined);
                  });
              }, 300));
          break;

        case title.tags:
          dispatch(getAllTags())
            .then(unwrapResult)
            .then((res) => {
              setFilters([]);
              res.data.map((x) => setFilters((old) => [...old, { title: x.value, id: x.id }]));
              setLoading(false);
            });

          break;

        default:
          if (filterId) {
            switch (header[header.length - 2]) {
              case title.collections:
                value.length === 0
                  ? dispatch(getProductByCollection({ id: filterId }))
                      .then(unwrapResult)
                      .then((res) => {
                        setProducts(res.data);
                        setLoading(false);
                      })
                  : (getData = setTimeout(() => {
                      dispatch(searchProductByCollection({ id: filterId, query: value }))
                        .then(unwrapResult)
                        .then((res) => {
                          setProducts(res.data);
                          setLoading(false);
                        });
                    }, 300));
                break;
              case title.types:
                value.length === 0
                  ? dispatch(getProductByType({ id: filterId, query: "" }))
                      .then(unwrapResult)
                      .then((res) => {
                        setProducts(res.data);
                        setLoading(false);
                      })
                  : (getData = setTimeout(() => {
                      dispatch(getProductByType({ id: filterId, query: value }))
                        .then(unwrapResult)
                        .then((res) => {
                          setProducts(res.data);
                          setLoading(false);
                        });
                    }, 300));
                break;
              default:
                setFilterId(undefined);
                setProducts([]);
                setLoading(false);
            }
          } else {
            setProducts([]);
            setLoading(false);
          }
          setFilters([]);
          setVariants([]);
      }
    } catch (e) {
      setLoading(false);
    }
    return () => clearTimeout(getData);
  }, [header, value, filterId]);

  const Category = ({ name, id }: { name: string; id?: number }) => (
    <div
      style={{
        margin: header[header.length - 1] === title.root ? "24px -24px -24px -24px" : "0px",
        cursor: "pointer",
      }}
      onClick={() => {
        setTitle((old) => [...old, name]);
        setFilterId(id);
        setLoading(true);
      }}
    >
      <div
        style={{
          borderWidth: "1px",
          borderStyle: "solid",
          borderColor: "#d9d9d9",
          padding: "1rem",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <p style={{ margin: "0px" }}>{name}</p>
        <IoIosArrowForward />
      </div>
    </div>
  );

  return (
    <Modal
      destroyOnClose
      visible={open}
      onCancel={() => {
        setOpen(false);
        setSearch("");
        setActiveKey(undefined);
        setFilters([]);
        setVariants([]);
        setProducts([]);
        setLoading(false);
        setVariantLoading(false);
        setTitle([title.root]);
      }}
      onOk={async () => {
        setOpen(false);
        setSearch("");
        setActiveKey(undefined);
        setFilters([]);
        setVariants([]);
        setProducts([]);
        setLoading(false);
        setVariantLoading(false);
        setTitle([title.root]);
        dispatch(setOrderLoading(true));
        let data = {
          line_items: selectedProducts.map((x) => {
            return {
              variant_id: parseInt(x.toString().split(",")[0]),
              quantity: 1,
              // price: parseInt(x.toString().split(",")[1]),
              applied_discount: {
                description: "Client applied_discount",
                value_type: "fixed_amount",
                value: `${
                  discounts.find((y) => y.id === parseInt(x.toString().split(",")[0]))?.value
                }`,
                amount: `${
                  discounts.find((y) => y.id === parseInt(x.toString().split(",")[0]))?.value
                }`,
                title: "Client applied_discount",
              },
            };
          }),
          note,
          customer: {
            id: Customer?.id ?? 0,
          },
          use_customer_default_address:
            JSON.stringify(Customer?.default_address) === JSON.stringify(shippingAddress) &&
            JSON.stringify(Customer?.default_address) === JSON.stringify(billingAddress),
        };
        orders?.id
          ? dispatch(
              editOrder({
                id: orders.id,
                data: {
                  line_items: [
                    ...orders.line_items,
                    ...selectedProducts
                      .filter((y) =>
                        orders.line_items?.every(
                          (variant) => variant.variant_id !== parseInt(y.toString().split(",")[0])
                        )
                      )
                      .map((x) => {
                        console.log({
                          discount: discounts.find(
                            (y) => y.id === parseInt(x.toString().split(",")[0])
                          )?.value,
                        });

                        return {
                          variant_id: parseInt(x.toString().split(",")[0]),
                          quantity: 1,
                          // price: parseInt(x.toString().split(",")[1]),
                          applied_discount: {
                            description: "Client applied_discount",
                            value_type: "fixed_amount",
                            value: `${
                              discounts.find((y) => y.id === parseInt(x.toString().split(",")[0]))
                                ?.value
                            }`,
                            amount: `${
                              discounts.find((y) => y.id === parseInt(x.toString().split(",")[0]))
                                ?.value
                            }`,
                            title: "Client applied_discount",
                          },
                        };
                      }),
                  ],
                },
              })
            )
              .then(unwrapResult)
              .then((res) => {
                dispatch(setOrders(res.data));
                dispatch(setOrderLoading(false));
              })
          : dispatch(
              JSON.stringify(Customer?.default_address) === JSON.stringify(shippingAddress) &&
                JSON.stringify(Customer?.default_address) === JSON.stringify(billingAddress)
                ? createOrder({ ...data })
                : createOrder({
                    ...data,
                    billing_address: billingAddress,
                    shipping_address: shippingAddress,
                  })
            )
              .then(unwrapResult)
              .then((res) => {
                dispatch(setOrders(res.data));
                dispatch(setOrderLoading(false));
              });
      }}
      okButtonProps={{ disabled: selectedProducts.length === 0 }}
      okText="Add"
      title={
        <div style={{ display: "flex", alignItems: "center" }}>
          {header[header.length - 1] !== title.root && (
            <div
              style={{ cursor: "pointer", display: "flex", alignItems: "center" }}
              onClick={() => {
                setTitle(header.splice(0, header.length - 1));
                setSearch("");
              }}
            >
              <IoArrowBackCircleOutline size={"22px"} />
            </div>
          )}
          &nbsp;&nbsp;{header[header.length - 1]}
        </div>
      }
    >
      <Input
        ref={input}
        placeholder={`Search ${
          header[header.length - 1] === title.root ? title.all : header[header.length - 1]
        }`}
        style={{ width: "100%", marginRight: "10px" }}
        // onSelect={(val) => setPromotionActivation(val as string)}
        // disabled={!!promotion?.id}
        value={value}
        prefix={<IoIosSearch />}
        onChange={(e) => {
          setValue(e.target.value);
        }}
      />
      {loading ? (
        <div className="d-center" style={{ height: "50vh" }}>
          <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
        </div>
      ) : header[header.length - 1] === title.root ? (
        categories.map((name) => <Category name={name} />)
      ) : header[header.length - 1] === title.collections ||
        header[header.length - 1] === title.types ||
        header[header.length - 1] === title.tags ? (
        <div
          style={{
            margin: "24px -24px -24px -24px",
            overflowY: "scroll",
            overflowX: "hidden",
            maxHeight: "50vh",
          }}
        >
          {filters.map((collection) => (
            <Category name={collection.title} id={collection.id} />
          ))}
        </div>
      ) : header[header.length - 1] === title.all ||
        header[header.length - 1] === title.clientProducts ||
        header[header.length - 2] === title.collections ||
        header[header.length - 2] === title.types ||
        header[header.length - 2] === title.tags ? (
        products.length === 0 ? (
          <p style={{ marginTop: "8px" }}>Your store doesn’t have any products yet.</p>
        ) : (
          <Checkbox.Group
            value={selectedProducts}
            onChange={(e) => {
              setSelectedProducts(
                union(
                  selectedProducts.filter((y) =>
                    variants?.every(
                      (variant) => variant.id !== parseInt(y.toString().split(",")[0])
                    )
                  ),
                  e
                )
              );
              setDiscounts(
                union(
                  discounts.filter((y) => variants?.every((variant) => variant.id !== y.id)),
                  e.map((x) => {
                    return {
                      id: parseInt(x.toString().split(",")[0] ?? "0"),
                      value:
                        (variants.find((x) => x.id === parseInt(e.toString().split(",")[0]))
                          ?.price ?? 0) -
                        (variants.find((x) => x.id === parseInt(e.toString().split(",")[0]))
                          ?.client_price ?? 0) /
                          100,
                    };
                  })
                )
              );
            }}
            style={{
              margin: "24px -24px -24px -24px",
              width: "520px",
              overflowY: "scroll",
              maxHeight: "50vh",
            }}
          >
            <Collapse style={{ padding: "0px" }} expandIconPosition="right" activeKey={activeKey}>
              {products.map((x) => (
                <Panel
                  key={x.id}
                  header={
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "start",
                        alignItems: "center",
                        padding: "0px",
                      }}
                      onClick={() => {
                        console.log({ id: x.id });
                        activeKey === x.id ? setActiveKey(undefined) : setActiveKey(x.id);
                        setVariantLoading(true);
                        dispatch(
                          getVariantsById({ customerid: Customer?.id ?? 0, productid: x.id })
                        )
                          .then(unwrapResult)
                          .then((res) => {
                            // console.log({ res });
                            setVariants(res.data);
                            setVariantLoading(false);
                          });
                      }}
                    >
                      {/* <Checkbox
                    // indeterminate={indeterminate}
                    // onChange={onCheckAllChange}
                    // checked={checkAll}
                    />
                    &nbsp;&nbsp; */}
                      <img
                        style={{ aspectRatio: "1 / 1", objectFit: "cover", height: "40px" }}
                        src={x.productImages[0]?.src}
                      />
                      &nbsp;&nbsp;<div>{x.title}</div>
                    </div>
                  }
                >
                  {variantLoading ? (
                    <div className="d-center" style={{ height: "50vh" }}>
                      <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
                    </div>
                  ) : (
                    <div style={{ width: "100%" }}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                          borderWidth: "0.5px",
                          padding: "4px 8px 4px 28px",
                          borderStyle: "solid",
                          borderColor: "#d9d9d9",
                        }}
                      >
                        <div>Variants</div>
                        <div style={{ display: "flex" }}>
                          <div
                            style={{
                              color: "#1890ff",
                              cursor: "pointer",
                              display: "flex",
                              alignItems: "center",
                            }}
                            onClick={(e) => {
                              setSelectedProducts(
                                union(
                                  selectedProducts,
                                  variants?.map((y) => `${y.id},${y.client_price / 100}`)
                                )
                              );
                              setDiscounts(
                                union(
                                  discounts,
                                  variants?.map((y) => {
                                    return { id: y.id, value: y.price - y.client_price / 100 };
                                  })
                                )
                              );
                            }}
                          >
                            <BiSolidSelectMultiple color="#1890ff" /> Select All
                          </div>
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          <div
                            style={{
                              color: "#1890ff",
                              cursor: "pointer",
                              display: "flex",
                              alignItems: "center",
                            }}
                            onClick={(e) => {
                              setSelectedProducts(
                                selectedProducts.filter((y) =>
                                  variants?.every(
                                    (variant) => variant.id !== parseInt(y.toString().split(",")[0])
                                  )
                                )
                              );
                              setDiscounts(
                                discounts.filter((y) =>
                                  variants?.every((variant) => variant.id !== y.id)
                                )
                              );
                            }}
                          >
                            <MdDeselect color="#1890ff" /> Select None
                          </div>
                        </div>
                      </div>
                      {variants?.map((y) => (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: "100%",
                            borderWidth: "0.5px",
                            padding: "4px 28px",
                            borderStyle: "solid",
                            borderColor: "#d9d9d9",
                          }}
                        >
                          <Checkbox
                            value={`${y.id},${y.client_price / 100}`}
                            disabled={
                              orders?.line_items.find((x) => x.variant_id === y.id) !== undefined
                            }
                          >
                            {y.title}
                          </Checkbox>
                          <div>
                            {y.inventory_quantity ?? 0} Available &nbsp;&nbsp;&nbsp;&nbsp; $
                            {y.client_price / 100 ?? y.price} USD
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </Panel>
              ))}
            </Collapse>
          </Checkbox.Group>
        )
      ) : (
        <p>no data</p>
      )}
    </Modal>
  );
};
