import { Route, Switch, useHistory, useRouteMatch } from "react-router-dom";
import { Breadcrumb, Button, Col, Input, PageHeader, Pagination, Row, Space } from "antd";
import React, { useEffect, useState } from "react";
import { RootState, useAppDispatch } from "../../store/store";
import { ordersIndex } from "../../store/ordersSlice/ordersSlice";
import { useSelector } from "react-redux";
import { Order } from "../../interfaces/Order";
import {
  clearPaginatedOrders,
  ordersPaginationSelectors,
} from "../../store/ordersSlice/ordersPaginationSlice";
import { OrdersTable } from "./OrdersTable";
import { OrderView } from "./OrderView";
import { NewOrder } from "./createOrder/NewOrder";

export const Orders = () => {
  const history = useHistory();
  const { path } = useRouteMatch();

  const dispatch = useAppDispatch();

  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const total = useSelector(ordersPaginationSelectors.total);
  const availablePages = useSelector(ordersPaginationSelectors.pages.selectIds);

  const orders = useSelector((state: RootState) =>
    ordersPaginationSelectors.get(state, currentPage)
  ) as Order[];

  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    if (availablePages && !loading && !availablePages.includes(currentPage)) {
      setLoading(true);
      dispatch(ordersIndex({ page: currentPage, searchTerm })).then(() => setLoading(false));
    }
  }, [currentPage, orders]);

  useEffect(() => {
    setLoading(true);
    dispatch(clearPaginatedOrders());
    dispatch(ordersIndex({ searchTerm })).then(() => {
      setCurrentPage(1);
      setLoading(false);
    });
  }, [searchTerm]);

  return (
    <Row className="page orders">
      <Switch>
        <Route exact path={path}>
          <Breadcrumb>
            <Breadcrumb.Item key="dashboard">Dashboard</Breadcrumb.Item>
            <Breadcrumb.Item key="orders">Orders</Breadcrumb.Item>
          </Breadcrumb>
          <Col sm={24}>
            <PageHeader
              title="Orders"
              className="site-page-header"
              onBack={() => history.push("/")}
              extra={[
                <Row>
                  <Col sm={14}>
                    <Input
                      addonBefore="Filter orders"
                      value={searchTerm}
                      onChange={(e) => {
                        setSearchTerm(e.target.value);
                      }}
                    />
                  </Col>
                  <Col sm={10}>
                    <Space>
                      <Button onClick={() => dispatch(clearPaginatedOrders())}>Reload</Button>

                      <Button type="primary" onClick={() => history.push("orders/new")}>Create Draft Order</Button>
                    </Space>
                  </Col>
                </Row>,
              ]}
            />
          </Col>
          <Col sm={24}>
            <OrdersTable orders={orders} loading={loading} />
            <div style={{ display: "flex", justifyContent: "right", padding: "15px 0" }}>
              <Pagination
                current={currentPage}
                total={total}
                pageSize={15}
                showSizeChanger={false}
                onChange={setCurrentPage}
              />
            </div>
          </Col>
        </Route>
        <Route path={`${path}/new`} component={NewOrder} />
        <Route path={`${path}/edit/:id`} component={NewOrder} />
        <Route path={`${path}/:id`} component={OrderView} />
      </Switch>
    </Row>
  );
};
