import { Route, Switch, useHistory, useRouteMatch } from "react-router-dom";
import { Breadcrumb, Button, Col, Input, PageHeader, Pagination, Row, Space } from "antd";
import React, { useEffect, useState } from "react";
import { RootState, useAppDispatch } from "../../store/store";
import { useSelector } from "react-redux";

import { DraftView } from "./DraftView";
import { draftIndex } from "../../store/draftSlice/draftSlice";
import { DraftsTable } from "./DraftsTable";
import {
  clearPaginatedDraft,
  draftPaginationSelectors,
} from "../../store/draftSlice/draftPaginationSlice";
import { draft, draftTable } from "../../interfaces/Drafts";
import { Draft, unwrapResult } from "@reduxjs/toolkit";

export const Drafts = () => {
  const history = useHistory();
  const { path } = useRouteMatch();

  const dispatch = useAppDispatch();

  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const total = useSelector(draftPaginationSelectors.total);
  const availablePages = useSelector(draftPaginationSelectors.pages.selectIds);

  const drafts = useSelector((state: RootState) =>
    draftPaginationSelectors.get(state, currentPage)
  ) as draftTable[];

  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    if (availablePages && !loading && !availablePages.includes(currentPage)) {
      setLoading(true);
      dispatch(draftIndex({ page: currentPage, searchTerm })).then(() => setLoading(false));
    }
  }, [currentPage, drafts]);

  useEffect(() => {
    setLoading(true);
    try {
      dispatch(clearPaginatedDraft());
      dispatch(draftIndex({ searchTerm })).then(() => {
        setCurrentPage(1);
        setLoading(false);
      });
    } catch (e) {
      setLoading(false);
    }
  }, [searchTerm]);

  return (
    <Row className="page drafts">
      <Switch>
        <Route exact path={path}>
          {/* <div style={{ overflow: "scroll" }}> */}
            <Breadcrumb>
              <Breadcrumb.Item key="dashboard">Dashboard</Breadcrumb.Item>
              <Breadcrumb.Item key="drafts">Drafts</Breadcrumb.Item>
            </Breadcrumb>
            <Col sm={24}>
              <PageHeader
                title="Drafts"
                className="site-page-header"
                onBack={() => history.push("/")}
                extra={[
                  <Row>
                    <Col sm={14}>
                      <Input
                        addonBefore="Search Drafts"
                        value={searchTerm}
                        onChange={(e) => {
                          setSearchTerm(e.target.value);
                        }}
                      />
                    </Col>
                    <Col sm={10}>
                      <Space>
                        <Button onClick={() => dispatch(clearPaginatedDraft())}>Reload</Button>

                        <Button type="primary" onClick={() => history.push("orders/new")}>
                          Create Draft Order
                        </Button>
                      </Space>
                    </Col>
                  </Row>,
                ]}
              />
            </Col>
            <Col sm={24}>
              <DraftsTable drafts={drafts} loading={loading} />
              <div style={{ display: "flex", justifyContent: "right", padding: "15px 0" }}>
                <Pagination
                  current={currentPage}
                  total={total}
                  pageSize={15}
                  showSizeChanger={false}
                  onChange={setCurrentPage}
                />
              </div>
            </Col>
          {/* </div> */}
        </Route>
        <Route path={`${path}/:id`} component={DraftView} />
      </Switch>
    </Row>
  );
};
