import { Button, DatePicker, Modal, Select, TimePicker } from "antd";
import { range } from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";

export const ReserveItemModal = ({
  open,
  setOpen,
  CustomerId,
  reservation,
  setReservation,
}: {
  open: boolean;
  reservation?: { date: string; time: string };
  setReservation: Function;
  setOpen: Function;
  CustomerId: number;
}) => {
  const date = new Date();
  const [endDate, setEndDate] = useState(
    moment(date.setDate(date.getDate())).format("MMMM D, yyyy ").toString()
  );
  const [endTime, setEndTime] = useState(moment("23:59", "h:mm").toString());
  const [selectedDate, setSelectedDate] = useState(
    moment(date.setDate(date.getDate())).format("MMMM D, yyyy ").toString()
  );
  const [isToday, setIsToday] = useState<boolean>(false);

  function disabledDateTime() {
    return {
      disabledHours: () => range(0, 24).splice(0, moment().hour()),
      disabledMinutes: () => range(0, 60).splice(0, moment().minute()),
      disabledSeconds: () => range(0, 60).splice(0, moment().seconds()),
    };
  }

  const options = [
    {
      label: `Today (${moment(date.setDate(date.getDate())).format("MMMM D, yyyy ")} at 11:59 pm)`,
      value: moment(date.setDate(date.getDate())).format("MMMM D, yyyy ").toString(),
    },
    {
      label: `Tomorrow (${moment(date.setDate(date.getDate() + 1)).format(
        "MMMM D, yyyy "
      )} at 11:59 pm)`,
      value: moment(date.setDate(date.getDate() + 1))
        .format("MMMM D, yyyy ")
        .toString(),
    },
    {
      label: `In a week (${moment(date.setDate(date.getDate() + 7))
        .format("MMMM D, yyyy ")
        .toString()} at 11:59 pm)`,
      value: moment(date.setDate(date.getDate() + 7))
        .format("MMMM D, yyyy ")
        .toString(),
    },
    { label: "Custom", value: "custom" },
  ];

  useEffect(() => {
    selectedDate !== "custom" && setEndDate(selectedDate);
    selectedDate !== "custom" && setEndTime(moment("23:59", "h:mm").toString());
    console.log({ selectedDate });
  }, [selectedDate]);

  useEffect(() => {
    console.log({
      value: options,
      endDate,
      check: !options.find((x) => {
        return x.value === endDate;
      }),
    });

    if (
      reservation &&
      !options.find((x) => {
        return x.value === moment(reservation.date).format("MMMM D, yyyy ").toString();
      })
    ) {
      setSelectedDate("custom");
    }
    let today = new Date();

    setIsToday(moment(endDate).format("YYYY-MM-DD") === moment(today).format("YYYY-MM-DD"));
  }, [endDate]);

  return (
    <Modal
      destroyOnClose
      visible={open}
      onCancel={() => {
        setOpen(false);
        setEndDate("");
        setSelectedDate(moment(date.setDate(date.getDate())).format("MMMM D, yyyy ").toString());
        setEndTime(moment("23:59", "h:mm").toString());
      }}
      okText="Done"
      onOk={() => {
        setOpen(false);
        setReservation({ date: endDate, time: endTime });
      }}
      title={"Reserve item"}
      //   footer={<Button color="red">clear Reservation</Button>}
    >
      <p style={{ opacity: "50%" }}>
        Items will be held as unavailable inventory until the end date.
      </p>
      <div>
        <div>End date</div>
        <Select
          value={selectedDate}
          style={{ width: "100%" }}
          onChange={(e) => setSelectedDate(e)}
          options={options}
        />
      </div>

      {selectedDate === "custom" && (
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(2, minmax(0, 1fr))",
            gap: "1rem",
            marginTop: "1rem",
          }}
        >
          {" "}
          <div>
            <label htmlFor="">Date</label>
            <DatePicker
              value={moment(endDate)}
              disabledDate={(current) => {
                let customDate = moment().format("YYYY-MM-DD");
                return current && current < moment(customDate, "YYYY-MM-DD");
              }}
              format={"MMMM D, yyyy"}
              onChange={(e) => setEndDate(e?.toString() ?? "")}
            />
          </div>
          <div>
            <div>Time</div>
            <TimePicker
              use12Hours
              disabledHours={isToday ? disabledDateTime().disabledHours : undefined}
              disabledMinutes={isToday ? disabledDateTime().disabledMinutes : undefined}
              disabledSeconds={isToday ? disabledDateTime().disabledSeconds : undefined}
              value={moment(endTime)}
              onChange={(e) => setEndTime(e?.toString() ?? "")}
            />
          </div>
        </div>
      )}
      {reservation && (
        <Button
          style={{ color: "red", marginTop: "1rem" }}
          onClick={() => {
            setReservation(undefined);
            setSelectedDate(
              moment(date.setDate(date.getDate())).format("MMMM D, yyyy ").toString()
            );
            setOpen(false);
          }}
        >
          Clear reservation
        </Button>
      )}
    </Modal>
  );
};
