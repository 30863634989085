import { unwrapResult } from "@reduxjs/toolkit";
import { Button, Card, Dropdown, Menu, Select, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { BiDotsHorizontalRounded, BiPlus } from "react-icons/bi";
import { Link } from "react-router-dom";
import { Address, getDetails } from "../../../../store/customersSlice/customersSlice";
import { LoadingOutlined } from "@ant-design/icons";
import { LabeledValue } from "antd/lib/select";
import { Person, searchCustomer } from "../../../../store/ordersSlice/ordersProductSlice";
import { useAppDispatch, useAppSelector } from "../../../../store/store";
import { CreateCustomerModal } from "../modals/Customer/createCustomerModals";
import { EditAddressModal } from "../modals/Customer/editAddressModal";
import {
  setBilling,
  setCustomer,
  setOrders,
  setShipping,
  setLoading as setOrderLoading,
  setEmail,
} from "../../../../store/draftSlice/createDraftSlice";
import { editOrder } from "../../../../store/draftSlice/draftSlice";
import { EditContactModal } from "../modals/Customer/editContactsModal";

export const CustomerCard = () => {
  const [loading, setLoading] = useState(false);
  const [customerLoading, setCustomerLoading] = useState(false);
  const [newCustomer, setNewCustomer] = useState(false);
  const [editContacts, setEditContacts] = useState(false);
  const [adressEdit, setAddressEdit] = useState<"shipping" | "billing" | "">("");
  const [customerList, setCustomerList] = useState<LabeledValue[]>([]);
  const [customerSelected, setCustomerSelected] = useState<LabeledValue>();
  const dispatch = useAppDispatch();
  const [customerdata, setCustomerData] = useState<Person[]>([]);
  const [customerSearch, setCustomerSearch] = useState("");
  const orders = useAppSelector((state) => state.newdraft.orders);
  const orderLoading = useAppSelector((state) => state.newdraft.loading);
  const billingAddress = useAppSelector((state) => state.newdraft.billingAddress);
  const shippingAddress = useAppSelector((state) => state.newdraft.shippingAddress);
  const phone = useAppSelector((state) => state.newdraft.shippingAddress?.phone);
  const email = useAppSelector((state) => state.newdraft.email);
  const selectedCustomerdata = useAppSelector((state) => state.newdraft.customer);
  const DisplayAddress = ({
    address,
    type,
  }: {
    address?: Address;
    type: "shipping" | "billing";
  }) => {
    return (
      <>
        {address ? (
          <>
            {" "}
            <p style={{ textAlign: "left", marginBottom: "0px" }}>{address.address1} </p>
            <p style={{ textAlign: "left", marginBottom: "0px" }}>{address.address2} </p>
            <p style={{ textAlign: "left", marginBottom: "0px" }}>
              {address.city && `${address.city},`} {address.province}{" "}
            </p>
            <p style={{ textAlign: "left" }}>
              {address.country} {address.country && address.zip && " - "} {address.zip}{" "}
            </p>
          </>
        ) : (
          <p style={{ opacity: "50%", textAlign: "left" }}>No {type} Address</p>
        )}
      </>
    );
  };

  const setSearchedList = (searchValue: string) => {
    dispatch(searchCustomer({ searchValue }))
      .then(unwrapResult)
      .then((response) => {
        setCustomerData(response.data);
        setCustomerList([]);
        response.data.map((x) => {
          setCustomerList((old) => [
            ...old,
            { value: x.id, label: x.firstName + " " + x.lastName + " (" + x.email + ")" },
          ]);
        });
        setLoading(false);
      });
  };

  useEffect(() => {
    console.log({ customerSearch });
    const getData = setTimeout(() => {
      setSearchedList(customerSearch);
    }, 300);

    return () => clearTimeout(getData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerSearch]);

  return (
    <>
      <CreateCustomerModal open={newCustomer} setOpen={setNewCustomer} />
      {selectedCustomerdata && (
        <>
          {" "}
          <EditContactModal open={editContacts} setOpen={setEditContacts} />
          <EditAddressModal setOpen={setAddressEdit} open={adressEdit} />
        </>
      )}
      <Card
        style={
          {
            // borderRadius: "0.5rem",
            // boxShadow: " 0 2px 4px 0 rgba(0, 0, 0, 0.1), 0 6px 10px 0 rgba(0, 0, 0, 0.19)",
          }
        }
      >
        {orderLoading || customerLoading ? (
          <div className="d-center" style={{ height: "50vh" }}>
            <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
          </div>
        ) : !selectedCustomerdata ? (
          <>
            <p style={{ textAlign: "left" }}>Customer</p>
            <Select
              dropdownRender={
                loading
                  ? () => (
                      <div className="d-center" style={{ padding: "8px" }}>
                        <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
                      </div>
                    )
                  : undefined
              }
              style={{ width: "100%" }}
              onSelect={(val) => {
                console.log({ val, customerdata });
                setCustomerSelected(val);
                setCustomerLoading(true);
                dispatch(
                  getDetails({
                    email:
                      customerdata?.find((x) => x.id === ((val as unknown) as number))?.email ?? "",
                  })
                )
                  .then(unwrapResult)
                  .then((res) => {
                    // console.log({ res });
                    if (orders?.id) {
                      dispatch(setOrderLoading(true));
                      dispatch(
                        editOrder({
                          id: orders.id,
                          data: { customer: res.data },
                        })
                      )
                        .then(unwrapResult)
                        .then((res) => {
                          dispatch(setOrders(res.data));
                          dispatch(setOrderLoading(false));
                        });
                    }
                    dispatch(setCustomer(res.data));
                    dispatch(setShipping({ ...res.data.default_address, phone: res.data.phone }));
                    dispatch(setBilling(res.data.default_address));
                    dispatch(setEmail(res.data.email));
                    setCustomerLoading(false);
                  });
              }}
              // disabled={!!promotion?.id}
              showSearch
              placeholder="Search for Customers"
              onChange={(e) => {
                console.log({ e });
              }}
              onFocus={() => {
                setLoading(true);
                setSearchedList("");
              }}
              onSearch={(value) => {
                // console.log({ value });
                if (value.length > 3) {
                  setCustomerList([]);
                  setLoading(true);
                  setCustomerSearch(value);
                } else if (value.length === 0) {
                  setLoading(true);
                  setCustomerList([]);
                  setSearchedList("");
                }
              }}
              filterOption={(input, option) =>
                (option?.label?.toString() ?? "").toLowerCase().includes(input.toLowerCase()) ||
                customerdata
                  .filter((x) => x.tags.find((y) => y.value.includes(input)))
                  ?.find((x) => x.id === option?.value) !== undefined
              }
              loading={loading}
              optionFilterProp="label"
              // dropdownRender={(menu) => (
              //   <div>
              //     <Button
              //       type="primary"
              //       style={{
              //         padding: "4px 8px",
              //         cursor: "pointer",
              //         width: "100%",
              //         display: "flex",
              //         alignItems: "center",
              //         justifyContent: "center",
              //         gap: "0.5rem",
              //       }}
              //       onMouseDown={(e) => e.preventDefault()}
              //       onClick={() => setNewCustomer(true)}
              //     >
              //       <BiPlus /> <div>Create a new customer</div>
              //     </Button>
              //     {menu}
              //   </div>
              // )}
              value={customerSelected}
              options={[...customerList]}
            ></Select>
          </>
        ) : (
          <div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <p style={{ fontWeight: "bold", marginBottom: "0px" }}>Customer</p>
              <Dropdown
                overlay={
                  <Menu>
                    {" "}
                    <Menu.Item>
                      <div
                        onClick={() => {
                          setEditContacts(true);
                        }}
                      >
                        Edit contact information
                      </div>
                    </Menu.Item>
                    <Menu.Item>
                      <div
                        onClick={() => {
                          setAddressEdit("shipping");
                        }}
                      >
                        Edit shipping information
                      </div>
                    </Menu.Item>
                    <Menu.Item>
                      <div
                        onClick={() => {
                          setAddressEdit("billing");
                        }}
                      >
                        Edit billing information
                      </div>
                    </Menu.Item>
                    {!orders?.id && (
                      <Menu.Item>
                        <div
                          style={{ color: "red" }}
                          onClick={() => {
                            setCustomerSelected(undefined);
                            dispatch(setCustomer(undefined));
                          }}
                        >
                          Remove Customer
                        </div>
                      </Menu.Item>
                    )}
                  </Menu>
                }
              >
                <div style={{ cursor: "pointer" }}>
                  {" "}
                  <BiDotsHorizontalRounded size={"20px"} />
                </div>
              </Dropdown>
            </div>
            <div style={{ display: "flex", justifyContent: "start" }}>
              <Link to="" style={{ width: "100%", textAlign: "left" }}>
                {selectedCustomerdata.first_name + " " + selectedCustomerdata.last_name}
              </Link>
            </div>
            {selectedCustomerdata.orders_count === 0 ? (
              <p style={{ opacity: "50%", textAlign: "left" }}>No Orders</p>
            ) : (
              <div style={{ marginBottom: "24px", display: "flex", justifyContent: "start" }}>
                <Link
                  to=""
                  onClick={(e) => {
                    e.preventDefault();
                  }}
                  style={{ width: "100%", textAlign: "left", color: "black" }}
                >
                  {selectedCustomerdata.orders_count + " Orders"}
                </Link>
              </div>
            )}

            <p style={{ fontWeight: "bold", textAlign: "left" }}>Contact Information</p>
            {email || selectedCustomerdata.email ? (
              <div style={{ display: "flex", justifyContent: "start" }}>
                <Link
                  to=""
                  onClick={(e) => {
                    e.preventDefault();
                  }}
                  style={{ width: "100%", textAlign: "left" }}
                >
                  {orders?.email ?? email}
                </Link>
              </div>
            ) : (
              <p style={{ opacity: "50%", textAlign: "left" }}>No Email</p>
            )}
            <p style={{ opacity: "50%", textAlign: "left" }}>
              {orders?.shipping_address?.phone ?? phone ?? "No Phone Number"}
            </p>

            <p style={{ fontWeight: "bold", textAlign: "left" }}>Shipping Address</p>
            <DisplayAddress
              address={orders?.shipping_address ?? shippingAddress}
              type={"shipping"}
            />

            <p style={{ fontWeight: "bold", textAlign: "left" }}>Billing Address</p>
            {shippingAddress === billingAddress ? (
              <p style={{ opacity: "50%", textAlign: "left" }}>Same as shipping address</p>
            ) : (
              <DisplayAddress
                address={orders?.billing_address ?? billingAddress}
                type={"billing"}
              />
            )}
          </div>
        )}
      </Card>
    </>
  );
};
