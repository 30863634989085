import { Button, Checkbox, Divider, Dropdown, Menu, Modal, Select, Spin } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { IoIosArrowDown } from "react-icons/io";
import { Phoneinput } from "../../../../../components/phoneInput";
import { countries } from "../../../../../shared/countries";
import { states } from "../../../../../shared/states";
import {
  Address,
  CustomerDetailsDataType,
} from "../../../../../store/customersSlice/customersSlice";
import { Person } from "../../../../../store/ordersSlice/ordersProductSlice";
import { InputComponent } from "./createCustomerModals";
import { LoadingOutlined } from "@ant-design/icons";
import { useAppDispatch, useAppSelector } from "../../../../../store/store";
import {
  setBilling,
  setLoading,
  setOrders,
  setShipping,
} from "../../../../../store/draftSlice/createDraftSlice";
import { ordersIndex } from "../../../../../store/ordersSlice/ordersSlice";
import { editOrder } from "../../../../../store/draftSlice/draftSlice";
import { unwrapResult } from "@reduxjs/toolkit";

export const EditAddressModal = ({
  open = "",
  setOpen,
}: {
  open?: "shipping" | "billing" | "";
  setOpen: Function;
}) => {
  const dispatch = useAppDispatch();

  const customer = useAppSelector((state) => state.newdraft.customer);
  const order = useAppSelector((state) => state.newdraft.orders);
  const billingAddress = useAppSelector((state) => state.newdraft.billingAddress);
  const shippingAddress = useAppSelector((state) => state.newdraft.shippingAddress);
  const [fName, setFName] = useState(customer?.first_name ?? "");
  const [addressID, setAddressID] = useState(customer?.default_address.id ?? "");
  const [lName, setLName] = useState(customer?.last_name ?? "");
  const [addressLine1, setAddressLine1] = useState(customer?.default_address?.address1 ?? "");
  const [addressLine2, setAddressLine2] = useState(customer?.default_address?.address2 ?? "");
  const [zipCode, setZipCode] = useState(customer?.default_address?.zip ?? "");
  const [phone, setPhone] = useState(customer?.phone ?? "");
  const [phoneInputError, setPhoneInputError] = useState("");
  const [city, setCity] = useState(customer?.default_address?.city ?? "");
  const [stateList, setStateList] = useState<{ label: string; value: string }[]>([]);
  const [state, setState] = useState(customer?.default_address?.province_code ?? "");
  const [country, setCountry] = useState(customer?.default_address?.country_code ?? "US");
  const [addresIndex, setAddressIndex] = useState<number>(0);
  const mobileValidation = useRef(true);

  useEffect(() => {
    setStateList([]);
    states
      .find((x) => x.name === countries.find((x) => x.value === country)?.label)
      ?.states.map((x) =>
        setStateList((old) => [
          ...old,
          {
            label: x.name,
            value: x.state_code ?? "",
          },
        ])
      );
  }, [country]);

  useEffect(() => {
    console.log({ open, customer, addresIndex });
    if (open === "shipping" && shippingAddress?.id === addressID) {
      setFName(shippingAddress?.first_name ?? customer?.addresses[addresIndex]?.first_name ?? "");
      setLName(shippingAddress?.last_name ?? customer?.addresses[addresIndex]?.last_name ?? "");
      setAddressLine1(
        shippingAddress?.address1 ?? customer?.addresses[addresIndex]?.address1 ?? ""
      );
      setAddressLine2(
        shippingAddress?.address2 ?? customer?.addresses[addresIndex]?.address2 ?? ""
      );
      setZipCode(shippingAddress?.zip ?? customer?.addresses[addresIndex]?.zip ?? "");
      setCity(shippingAddress?.city ?? customer?.addresses[addresIndex]?.city ?? "");
      setState(
        shippingAddress?.province_code ?? customer?.addresses[addresIndex]?.province_code ?? ""
      );
      setCountry(
        shippingAddress?.country_code ?? customer?.addresses[addresIndex]?.country_code ?? "US"
      );
      customer?.addresses &&
        setPhone(shippingAddress?.phone ?? customer?.addresses[addresIndex]?.phone ?? "");
    } else if (open === "billing" && billingAddress?.id === addressID) {
      setFName(billingAddress?.first_name ?? customer?.addresses[addresIndex]?.first_name ?? "");
      setLName(billingAddress?.last_name ?? customer?.addresses[addresIndex]?.last_name ?? "");
      setAddressLine1(billingAddress?.address1 ?? customer?.addresses[addresIndex]?.address1 ?? "");
      setAddressLine2(billingAddress?.address2 ?? customer?.addresses[addresIndex]?.address2 ?? "");
      setZipCode(billingAddress?.zip ?? customer?.addresses[addresIndex]?.zip ?? "");
      setCity(billingAddress?.city ?? customer?.addresses[addresIndex]?.city ?? "");
      setState(billingAddress?.province ?? customer?.addresses[addresIndex]?.province_code ?? "");
      setCountry(
        billingAddress?.country_code ?? customer?.addresses[addresIndex]?.country_code ?? "US"
      );
      customer?.addresses &&
        setPhone(billingAddress?.phone ?? customer?.addresses[addresIndex]?.phone ?? "");
    } else {
      let data = customer?.addresses ? customer?.addresses[addresIndex] : customer?.default_address;
      setFName(data?.first_name ?? "");
      setLName(data?.last_name ?? "");
      setAddressLine1(data?.address1 ?? "");
      setAddressLine2(data?.address2 ?? "");
      setZipCode(data?.zip ?? "");
      setCity(data?.city ?? "");
      setState(data?.province_code ?? "");
      setCountry(data?.country_code ?? "US");
      customer?.addresses && setPhone(data?.phone ?? "");
    }
  }, [addresIndex, open]);

  return (
    <Modal
      destroyOnClose
      visible={open !== ""}
      onCancel={() => {
        setOpen("");
        setFName("");
        setLName("");
        setAddressIndex(0);
        setAddressLine1("");
        setAddressLine2("");
        setZipCode("");
        setPhone("");
        setPhoneInputError("");
        setCity("");
        setState("");
        setCountry("US");
      }}
      style={{ width: "50vw" }}
      okText="Save"
      title={open === "shipping" ? "Edit shipping address" : "Edit billing address"}
      onOk={() => {
        let data: Address = {
          first_name: fName,
          last_name: lName,
          address1: addressLine1,
          address2: addressLine2,
          zip: zipCode,
          phone,
          city,
          province_code: state,
          province: stateList.find((x) => x.value === state)?.label ?? "",
          longitude:
            states.find((x) => x.name === country)?.states.find((x) => x.state_code === state)
              ?.longitude ?? "",
          latitude:
            states.find((x) => x.name === country)?.states.find((x) => x.state_code === state)
              ?.latitude ?? "",
          country: countries.find((x) => x.value === country)?.label ?? "",
          country_code: country,
          id: customer?.default_address?.id,
          customer_id: customer?.default_address?.customer_id,
          company: customer?.default_address?.company ?? "",
          name: customer?.default_address?.name ?? "",
          country_name: customer?.default_address?.country_name ?? "",
          default: false,
        };
        if (order?.id) {
          try {
            dispatch(setLoading(true));
            dispatch(
              editOrder({
                id: order.id,
                data: open === "shipping" ? { shipping_address: data } : { billing_address: data },
              })
            )
              .then(unwrapResult)
              .then((res) => {
                dispatch(setOrders(res.data));
                dispatch(setLoading(false));
              });
          } catch (e) {
            dispatch(setLoading(false));
          }
        }
        try {
          open === "shipping" ? dispatch(setShipping(data)) : dispatch(setBilling(data));
          setOpen("");
        } catch (e) {
          dispatch(setLoading(false));
        }
      }}
    >
      {open === "" ? (
        <div className="d-center" style={{ height: "50vh" }}>
          <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
        </div>
      ) : (
        <div style={{ maxHeight: "60vh", overflowY: "scroll", margin: "-24px", padding: "24px" }}>
          {customer?.addresses && (
            <Dropdown
              overlay={
                <Menu activeKey={addressID.toString()}>
                  {" "}
                  {customer?.addresses?.map((address, index) => (
                    <Menu.Item
                      active={address.id?.toString() === addressID.toString()}
                      key={address.id?.toString()}
                    >
                      <div
                        onClick={() => {
                          console.log({ index });
                          // setFName(address.first_name);
                          // setLName(address.last_name);
                          // setAddressLine1(address.address1);
                          // setAddressLine2(address.address2);
                          // setZipCode(address.zip);
                          // setPhone(address.phone ?? "");
                          // setPhoneInputError("");
                          // setCity(address.city);
                          // setState(address.province);
                          // setCountry(address.country_code);
                          setAddressID(address.id ?? "");
                          setAddressIndex(index);
                        }}
                      >
                        {" "}
                        <p style={{ textAlign: "left", marginBottom: "0px" }}>
                          {address.address1}{" "}
                        </p>
                        <p style={{ textAlign: "left", marginBottom: "0px" }}>
                          {address.address2}{" "}
                        </p>
                        <p style={{ textAlign: "left", marginBottom: "0px" }}>
                          {address.city}
                          {address.city && ","} {address.province}{" "}
                        </p>
                        <p style={{ textAlign: "left" }}>
                          {address.country} - {address.zip}{" "}
                        </p>
                      </div>
                    </Menu.Item>
                  ))}
                </Menu>
              }
              trigger={["click"]}
            >
              <Button
                style={{ display: "flex", alignItems: "center", gap: "4px", marginBottom: "16px" }}
              >
                <p style={{ marginBottom: "0px" }}>Select address</p> <IoIosArrowDown />
              </Button>
            </Dropdown>
          )}

          <div style={{ marginBottom: "24px" }}>
            <div>Country/region</div>
            <Select
              showSearch
              value={country}
              style={{ width: "100%" }}
              options={countries}
              optionFilterProp={"label"}
              onChange={(e) => {
                setCountry(e);
              }}
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              gap: "8px",
            }}
          >
            <InputComponent label={"First name"} state={fName} setState={setFName} />
            <InputComponent label={"Last name"} state={lName} setState={setLName} />
          </div>
          <InputComponent maxLength={32} label={"Address"} state={addressLine1} setState={setAddressLine1} />

          <InputComponent
            label={"Apartment, suite, etc."}
            state={addressLine2}
            setState={setAddressLine2}
          />
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              gap: "8px",
              marginBottom: "0px",
            }}
          >
            <InputComponent label={"City"} state={city} setState={setCity} />
            <div style={{ width: "100%" }}>
              <div>State</div>
              <Select
                showSearch
                value={state}
                style={{ width: "100%" }}
                options={[...stateList]}
                optionFilterProp={"label"}
                onChange={(e) => {
                  setState(e);
                }}
              />
            </div>
          </div>

          <InputComponent label={"ZIP code"} state={zipCode} setState={setZipCode} />
          <Phoneinput
            label={"Phone"}
            phone={phone}
            setPhone={setPhone}
            phoneInputError={phoneInputError}
            setPhoneInputError={setPhoneInputError}
            mobileValidation={mobileValidation}
          />
        </div>
      )}
    </Modal>
  );
};
