import { createSlice } from "@reduxjs/toolkit";
import { draft } from "../../interfaces/Drafts";
import { CustomerDetailsDataType, Address } from "../customersSlice/customersSlice";
import { createOrder, deleteDraft, draftIndex, editOrder, getOrder, saveDraft } from "./draftSlice";

export interface newDraft {
  customer?: CustomerDetailsDataType;
  billingAddress?: Address;
  shippingAddress?: Address;
  orders?: draft;
  phone?: string;
  note?: string;
  email?: string;
  loading: boolean;
}

const initialState: newDraft = {
  loading: false,
};

export const createDraftSlice = createSlice({
  name: "newDraft",
  initialState,
  reducers: {
    resetState: () => ({
      ...initialState,
    }),
    setBilling: (state, data: { payload: Address | undefined }) => ({
      ...state,
      billingAddress: data.payload,
    }),
    setShipping: (state, data: { payload: Address | undefined }) => ({
      ...state,
      shippingAddress: data.payload,
    }),
    saveNotes: (state, data: { payload: string | undefined }) => ({
      ...state,
      note: data.payload,
    }),
    setCustomer: (state, data: { payload: CustomerDetailsDataType | undefined }) => ({
      ...state,
      customer: data.payload,
    }),
    setLoading: (state, data: { payload: boolean }) => ({
      ...state,
      loading: data.payload,
    }),
    setOrders: (state, data: { payload: draft }) => ({
      ...state,
      orders: data.payload,
    }),
    setPhone: (state, data: { payload: string }) => ({
      ...state,
      shippingAddress: { ...state?.shippingAddress, phone: data.payload },
    }),
    setEmail: (state, data: { payload: string }) => ({
      ...state,
      email: data.payload,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(draftIndex.rejected, (state) => {
      return { ...state, loading: false };
    });
    builder.addCase(getOrder.rejected, (state) => {
      return { ...state, loading: false };
    });
    builder.addCase(editOrder.rejected, (state) => {
      return { ...state, loading: false };
    });
    builder.addCase(createOrder.rejected, (state) => {
      return { ...state, loading: false };
    });
    builder.addCase(saveDraft.rejected, (state) => {
      return { ...state, loading: false };
    });
    builder.addCase(deleteDraft.rejected, (state) => {
      return { ...state, loading: false };
    });
    
  },
});

export const {
  setLoading,
  setBilling,
  setShipping,
  setCustomer,
  setOrders,
  setPhone,
  saveNotes,
  setEmail,
  resetState,
} = createDraftSlice.actions;
