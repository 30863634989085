import { Prompt, Route, Switch, useHistory, useParams, useRouteMatch } from "react-router-dom";
import { Breadcrumb, Button, Col, Modal, PageHeader, Row } from "antd";
import React, { useEffect, useState } from "react";
import { OrderForm } from "./OrderForm";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import {
  resetState,
  setBilling,
  setCustomer,
  setLoading,
  setOrders,
  setShipping,
} from "../../../store/draftSlice/createDraftSlice";
import { deleteDraft, getOrder } from "../../../store/draftSlice/draftSlice";
import { unwrapResult } from "@reduxjs/toolkit";

export const NewOrder = () => {
  const { path } = useRouteMatch();
  const history = useHistory();
  const dispatch = useAppDispatch();
  //@ts-ignore
  const { id } = useParams();
  // const [isFormIncomplete, setIsFormIncomplete] = useState(true);
  const order = useAppSelector((state) => state.newdraft.orders);
  const [Delete, setDelete] = useState(false);
  const customer = useAppSelector((state) => state.newdraft.customer);
  const [formUnsaved, setFormUnsaved] = useState(false);

  // window.onbeforeunload = (event: any) => {
  //   // Show prompt based on state
  //   if (event && (order || customer)) {
  //     const e = event || window.event;
  //     e.preventDefault();
  //     if (e) {
  //       e.returnValue = "";
  //     }
  //     return "";
  //   }
  // };

  useEffect(() => {
    id && dispatch(setLoading(true));
    id
      ? dispatch(getOrder(id))
          .then(unwrapResult)
          .then((res) => {
            console.log({ res });
            dispatch(setOrders(res));
            dispatch(setCustomer(res.customer));
            dispatch(setShipping(res.shipping_address));
            res.shipping_address !== res.billing_address &&
              dispatch(setBilling(res.billing_address));
            dispatch(setLoading(false));
          })
      : dispatch(resetState());
  }, [id]);

  useEffect(() => {
    order?.id && setFormUnsaved(true);
  }, [order]);

  return (
    <Row className="page promotions">
      <Modal
        visible={Delete}
        onCancel={() => {
          setDelete(false);
        }}
        title="Delete"
        okText="Delete"
        okButtonProps={{ color: "red" }}
        onOk={() => {
          setFormUnsaved(false);
          order?.id && dispatch(deleteDraft(order?.id));
          history.go(-1);
        }}
      >
        Are you sure you want to delete?
      </Modal>
      {/* <Prompt when={formUnsaved} message="Leaving this page will delete all unsaved changes." /> */}
      <Switch>
        <Route exact path={path}>
          <Breadcrumb>
            <Breadcrumb.Item key="dashboard">Dashboard</Breadcrumb.Item>
            <Breadcrumb.Item key="order">Order</Breadcrumb.Item>
            <Breadcrumb.Item key="new">New</Breadcrumb.Item>
          </Breadcrumb>
          <Col sm={24}>
            <PageHeader
              title="New Order"
              className="site-page-header"
              onBack={() => history.go(-1)}
              extra={
                order?.id && [
                  <Button
                    type="primary"
                    style={{ background: "red", border: "None" }}
                    key="delete-btn"
                    onClick={() => {
                      setDelete(true);
                    }}
                  >
                    Delete Draft
                  </Button>,
                ]
              }
            />
          </Col>
          <Col sm={24}>
            <OrderForm setUnsaved={setFormUnsaved} />
          </Col>
        </Route>
      </Switch>
    </Row>
  );
};
