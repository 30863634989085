import { createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { AxiosError, AxiosResponse } from "axios";
import { api } from "../../api";
import { RootState } from "../store";

export type CategoryType = {
  data: string[];
};

type ProductImage = {
  id: number;
  productId: number;
  position: number;
  width: number;
  height: number;
  src: string;
  createdAt: string;
  updatedAt: string;
};

export type Variant = {
  id: number;
  productId: number;
  price: number;
  client_price: number;
  title: string;
  inventory_quantity: number;
  imageId: number | null;
  createdAt: string;
  updatedAt: string;
  sku: string;
  position: number;
  priceInCents: number;
  productImageId: number | null;
};

export type ProductType = {
  id: number;
  title: string;
  status: boolean;
  createdAt: string;
  updatedAt: string;
  productType: string;
  productTypeId: number;
  handle: string;
  productImages: ProductImage[];
  variants: Variant[];
};

export type ProductDataType = {
  data: ProductType[];
};

export type CollectionData = {
  data: {
    id: number;
    handle: string;
    title: string;
    createdAt: string;
    updatedAt: string;
    pilot: boolean;
    smart: boolean;
    storefrontPath: string;
  }[];
};

export type CategoryDataType = {
  data: {
    id: number;
    value: string;
  }[];
};

type Tag = {
  id: number;
  value: string;
  createdAt: string;
  updatedAt: string;
};

export type Person = {
  id: number;
  firstName: string;
  lastName: string;
  createdAt: string;
  updatedAt: string;
  payrollBalance: number;
  minimumPayrollTransactionAmount: number;
  allotmentBalance: number;
  email: string | null;
  phone: string;
  metafieldVersion: number;
  deletedAt: string | null;
  rank: number;
  tags: Tag[];
  tax_exempt: boolean;
  companyIssues: any[]; // You can replace `any` with an appropriate type if needed
  orders: any[]; // You can replace `any` with an appropriate type if needed
  transactions: any[]; // You can replace `any` with an appropriate type if needed
};

export type SearchDataContainer = {
  data: Person[];
};

export const productAdapter = createEntityAdapter();

export const axiosResHandle = <T>(res: AxiosResponse<T>) => res.data;
export const axiosResErr = <T>(res: AxiosError<T>) => res.message;
export const axiosResErrNoRes = <T>(res: AxiosError<T>) => res.message;
export const errUnknown = (res: unknown) => `${res}`;

const getCategoriesService = async () => {
  return api.get<CategoryType>(`/products/filters`);
};

export const getCategories = createAsyncThunk(
  "get/Categories",
  async (_params, { rejectWithValue }) => {
    try {
      const res = await getCategoriesService();
      return axiosResHandle(res);
    } catch (error) {
      throw rejectWithValue(error);
    }
  }
);

const getAllProductsService = async () => {
  return api.get<ProductDataType>(`/products/all`);
};

export const getAllProducts = createAsyncThunk(
  "products/all",
  async (_params, { rejectWithValue }) => {
    try {
      const res = await getAllProductsService();
      return axiosResHandle(res);
    } catch (error) {
      throw rejectWithValue(error);
    }
  }
);

const searchAllProductsService = async ({ query }: { query: string }) => {
  return api.get<ProductDataType>(`/products/search?q=${query}`);
};

export const searchAllProducts = createAsyncThunk(
  "products/search",
  async (
    {
      query,
    }: {
      query: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const res = await searchAllProductsService({ query });
      return axiosResHandle(res);
    } catch (error) {
      throw rejectWithValue(error);
    }
  }
);

const searchAllClientProductsService = async ({
  query,
  customerId,
}: {
  query: string;
  customerId: number;
}) => {
  return api.get<ProductDataType>(`/products/search/byclient?q=${query}&customer_id=${customerId}`);
};

export const searchAllClientProducts = createAsyncThunk(
  "products/search",
  async (
    {
      query,
      customerId,
    }: {
      query: string;
      customerId: number;
    },
    { rejectWithValue }
  ) => {
    try {
      const res = await searchAllClientProductsService({ query, customerId });
      return axiosResHandle(res);
    } catch (error) {
      throw rejectWithValue(error);
    }
  }
);

const getAllCollectionsService = async () => {
  return api.get<CollectionData>(`/products/collections/search`);
};

export const getAllCollections = createAsyncThunk(
  "products/collections",
  async (_params, { rejectWithValue }) => {
    try {
      const res = await getAllCollectionsService();
      return axiosResHandle(res);
    } catch (error) {
      throw rejectWithValue(error);
    }
  }
);

const searchAllCollectionsService = async ({ query }: { query: string }) => {
  return api.get<CollectionData>(`/products/collections/search?q=${query}`);
};

export const searchAllCollections = createAsyncThunk(
  "collections/search",
  async (
    {
      query,
    }: {
      query: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const res = await searchAllCollectionsService({ query });
      return axiosResHandle(res);
    } catch (error) {
      throw rejectWithValue(error);
    }
  }
);

const getProductByCollectionService = async (params: { id: number }) => {
  return api.get<ProductDataType>(`/products/collections/${params.id}/search`);
};

export const getProductByCollection = createAsyncThunk(
  "products/collections/filter/",
  async (params: { id: number }, { rejectWithValue }) => {
    try {
      const res = await getProductByCollectionService(params);
      return axiosResHandle(res);
    } catch (error) {
      throw rejectWithValue(error);
    }
  }
);

const searchProductByCollectionService = async (params: { id: number; query: string }) => {
  return api.get<ProductDataType>(`/products/collections/${params.id}/search?q=${params.query}`);
};

export const searchProductByCollection = createAsyncThunk(
  "products/collections/search/",
  async (params: { id: number; query: string }, { rejectWithValue }) => {
    try {
      const res = await searchProductByCollectionService(params);
      return axiosResHandle(res);
    } catch (error) {
      throw rejectWithValue(error);
    }
  }
);

const getAllTypesService = async (params: { query: string }) => {
  return api.get<CategoryDataType>(`/products/types/search?q=${params.query}`);
};

export const getAllTypes = createAsyncThunk(
  "products/types",
  async (params: { query: string }, { rejectWithValue }) => {
    try {
      const res = await getAllTypesService(params);
      return axiosResHandle(res);
    } catch (error) {
      throw rejectWithValue(error);
    }
  }
);

const getProductByTypeService = async (params: { id: number; query: string }) => {
  return api.get<ProductDataType>(`/products/types/${params.id}/search?q=${params.query}`);
};

export const getProductByType = createAsyncThunk(
  "products/types/filter/",
  async (params: { id: number; query: string }, { rejectWithValue }) => {
    try {
      const res = await getProductByTypeService(params);
      return axiosResHandle(res);
    } catch (error) {
      throw rejectWithValue(error);
    }
  }
);

const searchCustomerService = async (params: { searchValue: string }) => {
  return api.get<SearchDataContainer>(`customers/search?q=${params.searchValue}`);
};

export const searchCustomer = createAsyncThunk(
  "customers/search",
  async (params: { searchValue: string }, { rejectWithValue }) => {
    try {
      const res = await searchCustomerService(params);
      return axiosResHandle(res);
    } catch (error) {
      throw rejectWithValue(error);
    }
  }
);

const getVariantsByIdService = async (params: { customerid: number; productid: number }) => {
  return api.get<{ data: Variant[] }>(`products/details/${params.customerid}/${params.productid}`);
};

export const getVariantsById = createAsyncThunk(
  "variants/details",
  async (params: { customerid: number; productid: number }, { rejectWithValue }) => {
    try {
      const res = await getVariantsByIdService(params);
      return axiosResHandle(res);
    } catch (error) {
      throw rejectWithValue(error);
    }
  }
);

const getProductTagsService = async () => {
  return api.get<CategoryDataType>(`/products/tags`);
};

export const getAllTags = createAsyncThunk(
  "products/tags",
  async (_params, { rejectWithValue }) => {
    try {
      const res = await getProductTagsService();
      return axiosResHandle(res);
    } catch (error) {
      throw rejectWithValue(error);
    }
  }
);

const initialState: { categories: CategoryType | undefined } = { categories: undefined };

export const productSlice = createSlice({
  name: "product",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // builder.addCase(getCategories.fulfilled, (state, { payload:{data} }) => {
    //   return productAdapter.upsertMany(state, data);
    // });
  },
});

export const productSelector = productAdapter.getSelectors((s: RootState) => s.orders);
