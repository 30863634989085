import { unwrapResult } from "@reduxjs/toolkit";
import { Button, Checkbox, Divider, Form, Modal, Select } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { Phoneinput } from "../../../../../components/phoneInput";
import { countries } from "../../../../../shared/countries";
import { states } from "../../../../../shared/states";
import {
  CustomerDetailsDataType,
  editCustomer,
} from "../../../../../store/customersSlice/customersSlice";
import {
  setEmail,
  setLoading,
  setOrders,
  setPhone,
} from "../../../../../store/draftSlice/createDraftSlice";
import { editOrder } from "../../../../../store/draftSlice/draftSlice";
import { Person } from "../../../../../store/ordersSlice/ordersProductSlice";
import { useAppDispatch, useAppSelector } from "../../../../../store/store";
import { InputComponent } from "./createCustomerModals";

export const EditContactModal = ({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: Function;
}) => {
  const order = useAppSelector((state) => state.newdraft.orders);
  const Customer = useAppSelector((state) => state.newdraft.customer);
  const phone = useAppSelector((state) => state.newdraft.phone);
  const email = useAppSelector((state) => state.newdraft.email);
  const [checked, setChecked] = useState(false);
  const [tempEmail, setTempEmail] = useState(email ?? Customer?.email ?? "");
  const [tempPhone, setTempPhone] = useState(phone ?? Customer?.phone ?? "");
  const [phoneInputError, setPhoneInputError] = useState("");
  const mobileValidation = useRef(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (open) {
      setTempEmail(email ?? Customer?.email ?? "");
      setTempPhone(phone ?? Customer?.phone ?? "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const onSubmit = () => {
    setOpen(false);
    if (order?.id) {
      dispatch(setLoading(true));
      dispatch(
        editOrder({
          id: order.id,
          data: { shipping_address: { phone: tempPhone }, email: tempEmail },

          // customer: {...order.customer, phone: tempPhone, email: tempEmail } },
        })
      )
        .then(unwrapResult)
        .then((res) => {
          dispatch(setOrders(res.data));
          dispatch(setLoading(false));
        });
    }
    if (checked && Customer?.id) {
      dispatch(editCustomer({ email: tempEmail, phone: tempPhone, id: Customer?.id }));
    }
    dispatch(setPhone(tempPhone));
    dispatch(setEmail(tempEmail));
    setChecked(false);
  };

  return (
    <Modal
      destroyOnClose
      visible={open}
      onCancel={() => {
        setOpen(false);
        setTempEmail("");
        setChecked(false);
        setTempPhone("");
        setPhoneInputError("");
      }}
      style={{ width: "50vw" }}
      footer={false}
      title={"Edit contact information"}
    >
      <Form onFinish={onSubmit}>
        <InputComponent
          rules={[
            {
              required: true,
              message: "Please input  E-mail!",
            },
            {
              pattern: /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+\.[a-z]{2,3}$/gm,
              message: "Please enter email id in this valid format (abcd@mail.com)",
              validateTrigger: "onSubmit",
            },
          ]}
          label={"Email"}
          state={tempEmail}
          setState={setTempEmail}
        />
        {tempEmail.length > 0 &&
          !tempEmail.match(/^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+\.[a-z]{2,3}$/gm) && (
            <p style={{ marginTop: "-24px", color: "red" }}>Invalid email format</p>
          )}
        {tempEmail.length === 0 && (
          <p style={{ marginTop: "-24px", color: "red" }}>Please Enter an Email</p>
        )}

        <Phoneinput
          label={"Phone"}
          phone={tempPhone}
          setPhone={setTempPhone}
          phoneInputError={phoneInputError}
          setPhoneInputError={setPhoneInputError}
          mobileValidation={mobileValidation}
        />
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            height: "100%",
            alignItems: "center",
          }}
        >
          <Checkbox
            checked={checked}
            onChange={(e) => {
              setChecked(e.target.checked);
            }}
          >
            Update Customer Information
          </Checkbox>
          <div style={{ paddingTop: "12px", display: "flex", justifyContent: "end", gap: "4px" }}>
            <Button
              type="ghost"
              onClick={() => {
                setOpen(false);
                setTempEmail("");
                setChecked(false);
                setTempPhone("");
                setPhoneInputError("");
              }}
            >
              Cancle
            </Button>
            <Button
              htmlType="submit"
              type="primary"
              disabled={
                !tempEmail.match(/^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+\.[a-z]{2,3}$/gm) ||
                mobileValidation.current
              }
            >
              Save
            </Button>
          </div>
        </div>
      </Form>
    </Modal>
  );
};
