/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import { Card, Form, Input, Select, Button } from "antd";
import { Promotion } from "../../../interfaces/Promotion";
import { useForm } from "antd/es/form/Form";
import React, { useEffect, useState } from "react";
import { Person } from "../../../store/ordersSlice/ordersProductSlice";
import { PaymentsCard } from "./Cards/PaymentsCard";
import { ProductCard } from "./Cards/ProductCard";
import { CustomerCard } from "./Cards/CustomerCard";
import { Address, CustomerDetailsDataType } from "../../../store/customersSlice/customersSlice";
import { draft } from "../../../interfaces/Drafts";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import { saveNotes, setLoading, setOrders } from "../../../store/draftSlice/createDraftSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { editOrder } from "../../../store/draftSlice/draftSlice";

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

// const tailLayout = {
//   wrapperCol: { offset: 8, span: 16 },
// };

export const OrderForm = ({ setUnsaved }: { setUnsaved: Function }) => {
  const [form] = useForm();
  const dispatch = useAppDispatch();
  const originalNote = useAppSelector((state) => state.newdraft.orders?.note);
  const [notes, setNotes] = useState(originalNote ?? "");
  const [tagsList, setTagsList] = useState([]);
  const orders = useAppSelector((state) => state.newdraft.orders);
  const loading = useAppSelector((state) => state.newdraft.loading);

  const [tagsSelected, setTagsSelected] = useState([]);

  useEffect(() => {
    notes !== originalNote && setNotes(originalNote ?? "");
  }, [originalNote]);

  // const [promotionType, setPromotionType] = useState(PromotionType.FIXED);

  // const onSubmit = useCallback(async () => {
  //   try {
  //     await form.validateFields();
  //     const formData = form.getFieldsValue();
  //     formData.value =
  //       formData.type === PromotionType.FIXED ? dollarsToCents(formData.value) : formData.value;
  //     formData.minimumCartValue = formData.minimumCartValue
  //       ? dollarsToCents(formData.minimumCartValue)
  //       : formData.minimumCartValue;
  //     const [startsAt, endsAt] = formData.activeRange;
  //     await (promotion?.id
  //       ? dispatch(updatePromotion({ ...formData, startsAt, endsAt }))
  //       : dispatch(createPromotion({ ...formData, startsAt, endsAt }))
  //     ).then(unwrapResult);
  //     history.push("/discounts");
  //   } catch (e) {
  //     console.error(e);
  //   }
  // }, [form]);

  // useEffect(() => {
  //   if (promotion) {
  //     form.setFieldsValue({
  //       ...promotion,
  //       activeRange: [moment(promotion?.startsAt), moment(promotion?.endsAt)],
  //       value:
  //         promotion.type === PromotionType.FIXED
  //           ? centsToDollars(promotion.value as number)
  //           : promotion.value,
  //       minimumCartValue: promotion.minimumCartValue
  //         ? centsToDollars(promotion.minimumCartValue)
  //         : promotion.minimumCartValue,
  //     });
  //     setPromotionType(promotion.type as PromotionType);
  //     setPromotionActivation(promotion.code?.length ? "code" : "automatic");
  //   }
  // }, [promotion]);

  return (
    <div
      style={{
        display: "grid",
        margin: "auto",
        gridTemplateColumns: "repeat(3, minmax(0, 1fr))",
        gap: "1.5rem",
      }}
      // form={form}
      // name="promotion"
      {...layout}
    >
      <div
        style={{
          display: "flex",
          gridColumn: "span 2 / span 2",
          flexDirection: "column",
          gap: "1.5rem",
        }}
      >
        <ProductCard setUnsaved={setUnsaved}/>
        <PaymentsCard setUnsaved={setUnsaved} />
      </div>

      <div style={{ display: "flex", flexDirection: "column", gap: "1.5rem" }}>
        <Card>
          <p style={{ textAlign: "left" }}>Notes</p>
          <Input.TextArea
            rows={4}
            value={notes}
            onChange={(e) => setNotes(e.currentTarget.value)}
            onBlur={() => {
              setNotes(notes.trim());
            }}
          />
          {notes !== originalNote && notes !== "" && (
            <Button
              type="primary"
              disabled={loading}
              style={{ marginTop: "8px" }}
              onClick={() => {
                if (orders?.id) {
                  dispatch(setLoading(true));
                  dispatch(
                    editOrder({
                      id: orders.id,
                      data: { note: notes },
                    })
                  )
                    .then(unwrapResult)
                    .then((res) => {
                      dispatch(setOrders(res.data));
                      dispatch(setLoading(false));
                    });
                }
                dispatch(saveNotes(notes));
              }}
            >
              Save
            </Button>
          )}
        </Card>

        <CustomerCard />

        {/* <Card>
          <p style={{ textAlign: "left" }}>Tags</p>
          <Select
            style={{ width: "100%" }}
            // onSelect={(val) => setPromotionActivation(val as string)}
            // disabled={!!promotion?.id}
            placeholder="Add Tags"
            mode="tags"
            showSearch
            value={tagsSelected}
            onChange={(e) => {
              setTagsSelected(e);
            }}
            options={tagsList}
          ></Select>
        </Card> */}
      </div>
    </div>
  );
};
