/* eslint-disable jsx-a11y/anchor-is-valid */
import { Dropdown, Divider, Space, Button, Checkbox } from "antd";
import React from "react";
import { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../store/store";
import { unwrapResult } from "@reduxjs/toolkit";
import { setOrders, setLoading } from "../../../../../store/draftSlice/createDraftSlice";
import { editOrder } from "../../../../../store/draftSlice/draftSlice";

export const TaxDropdown = ({
  open,
  setOpen,
  children,
  wrapperRef,
}: {
  open: boolean;
  setOpen: Function;
  children: JSX.Element;
  wrapperRef: any;
}) => {
  const tax = useAppSelector((state) => state.newdraft.orders?.tax_exempt);
  const orders = useAppSelector((state) => state.newdraft.orders);
  const [taxed, setTaxed] = useState(tax);
  const dispatch = useAppDispatch();

  return (
    <div>
      <Dropdown
        // menu={{ items }}
        disabled={!orders?.id}
        visible={open}
        overlay={() => (
          <div
            ref={wrapperRef}
            className="dropdown-content"
            style={{
              backgroundColor: "white",
              filter: " drop-shadow(0 0 0.75rem )",
              padding: "1rem",
            }}
          >
            <p>Taxes are automatically calculated.</p>
            <Checkbox
              checked={!taxed}
              onChange={(e) => {
                setTaxed(!e.target.checked);
              }}
            >
              Charge taxes
            </Checkbox>
            <Divider style={{ margin: "8px 0px 0px 0px" }} />
            <Space style={{ padding: 8, display: "flex", justifyContent: "space-between" }}>
              <Button type="ghost" onClick={() => setOpen(false)}>
                Cancel
              </Button>
              <Button
                type="primary"
                disabled={tax === taxed}
                onClick={() => {
                  dispatch(setLoading(true));
                  setOpen(false);
                  dispatch(
                    editOrder({
                      id: orders?.id ?? 0,
                      data: {  tax_exempt: taxed },
                    })
                  )
                    .then(unwrapResult)
                    .then((res) => {
                      dispatch(setOrders(res.data));
                      dispatch(setLoading(false));
                    });
                }}
              >
                Apply
              </Button>
            </Space>
          </div>
        )}
      >
        <a
          onClick={(e) => {
            e.preventDefault();
            setOpen(!open);
          }}
        >
          <div style={{ textAlign: "left" }}>{children}</div>
        </a>
      </Dropdown>
    </div>
  );
};
