import { createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { Page } from "../../interfaces/Page";
import { Order } from "../../interfaces/Order";
import { RootState } from "../store";
import { draftIndex, draftSelector } from "./draftSlice";
import { draft, draftTable } from "../../interfaces/Drafts";

const draftPaginationAdapter = createEntityAdapter<Page>();

export const clearPaginatedDraft = createAsyncThunk("draft/clearPaginatedDraft", () =>
  Promise.resolve()
);

export const draftPaginationSlice = createSlice({
  name: "draftPagination",
  initialState: {
    pages: draftPaginationAdapter.getInitialState(),
    total: 0,
    filters: { searchTerm: "" },
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(draftIndex.fulfilled, (state, { payload }) => {
      return {
        pages: draftPaginationAdapter.upsertOne(
          { ...state.pages },
          { id: payload.page, items: payload.data.map((o: Order) => o.id) }
        ),
        total: payload.total,
        filters: payload.filters || {},
      };
    });
    builder.addCase(clearPaginatedDraft.fulfilled, (state, action) => ({
      pages: draftPaginationAdapter.getInitialState(),
      total: 0,
      filters: { searchTerm: "" },
    }));
  },
});

const adapterSelector = draftPaginationAdapter.getSelectors(
  (s: RootState) => s.draftPagination.pages
);

const empty: draftTable[] = [];

export const draftPaginationSelectors = {
  pages: adapterSelector,
  get: (state: RootState, page: number) => {
    return (
      adapterSelector
        .selectById(state, page)
        ?.items?.map((id) => draftSelector.selectById(state, id)) || empty
    );
  },
  total: (s: RootState) => s.draftPagination.total,
};
