import React, { useCallback, useEffect, useState } from "react";
import {
  Breadcrumb,
  Button,
  Col,
  Input,
  Modal,
  PageHeader,
  Pagination,
  Row,
  Select,
  Space,
  Table,
  Tag,
} from "antd";
import { Route, Switch, useHistory, useRouteMatch } from "react-router-dom";
import { NewPromotion } from "./NewPromotion";
import { useAppDispatch } from "../../store/store";
import { useSelector } from "react-redux";
import {
  deletePromotion,
  listPromotions,
  updatePromotion,
} from "../../store/promotionsSlice";
import { Promotion } from "../../interfaces/Promotion";
import { StatusToggleButton } from "../../components/status_toggle_button/StatusToggleButton";
import { EditPromotion } from "./EditPromotion";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { clientSelectors } from "../../store/clientsSlice/clientsSlice";
import { parseLabel } from "../../utils";
import { debounce } from "lodash";
import { unwrapResult } from "@reduxjs/toolkit";
import { PromotionType } from "../../enums/PromotionType";

export const Promotions = () => {
  const { path } = useRouteMatch();
  const history = useHistory();
  const dispatch = useAppDispatch();

  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [promotions, setPromotions] = useState<Promotion[]>([]);
  const [total, setTotal] = useState(1);
  const [filters, setFilters] = useState<any>({});

  const loadPromotions = async (page: number) => {
    setLoading(true);
    const {
      payload: { data, total, page: currentPage },
    } = await dispatch(listPromotions({ page, ...filters }));
    setPromotions(data);
    setTotal(total);
    setCurrentPage(currentPage);
    setLoading(false);
  };

  useEffect(() => {
    loadPromotions(1);
  }, []);

  useEffect(() => {
    loadPromotions(currentPage);
  }, [currentPage, filters]);

  const updateFilters = useCallback(
    debounce((updates: any) => {
      setCurrentPage(1);
      setFilters(updates);
    }, 300),
    []
  );

  const clients = useSelector(clientSelectors.selectEntities);

  return (
    <Row className="page promotions" style={{ textAlign: "start" }}>
      <Switch>
        <Route exact path={path}>
          <Breadcrumb>
            <Breadcrumb.Item key="dashboard">Dashboard</Breadcrumb.Item>
            <Breadcrumb.Item key="promotions">Discounts</Breadcrumb.Item>
          </Breadcrumb>
          <Col sm={24}>
            <PageHeader
              title="Discounts"
              className="site-page-header"
              onBack={() => history.push("/")}
              extra={[
                <Input
                  onChange={(e) => {
                    e.persist();
                    updateFilters({ ...filters, query: e.target.value });
                  }}
                  placeholder="Search with title"
                  style={{ width: 200 }}
                />,
                <Select
                  options={Object.values(PromotionType).map((type) => ({
                    label: parseLabel(type),
                    value: type,
                  }))}
                  onSelect={(val) => {
                    updateFilters({ ...filters, type: val });
                  }}
                  placeholder="Discount type"
                  style={{ width: 140 }}
                  allowClear
                  onClear={() => updateFilters({ ...filters, type: undefined })}
                />,
                <Select
                  options={Object.values(clients).map((client) => ({
                    label: client?.name as string,
                    value: client?.id as number,
                  }))}
                  onSelect={(clientId) => setFilters({ ...filters, clientId })}
                  onClear={() => setFilters({ ...filters, clientId: undefined })}
                  allowClear
                  style={{ width: 140 }}
                  placeholder="Client"
                />,

                <Button onClick={() => loadPromotions(currentPage)}>Reload</Button>,

                <Button
                  type="primary"
                  key="add-discount-btn"
                  onClick={() => history.push("/discounts/new")}
                >
                  Add Discount
                </Button>,
              ]}
            />
          </Col>
          <Col sm={24}>
            <Table dataSource={promotions} rowKey="id" pagination={false} loading={loading}>
              <Table.Column title="Title" dataIndex="title" key="title" />
              <Table.Column title="Type" key="type" render={({ type }) => parseLabel(type)} />
              <Table.Column
                title="Client"
                dataIndex="clientId"
                render={(clientId) => clients[clientId]?.tag}
              />
              <Table.Column
                title="Activation"
                render={(promotion) => {
                  return promotion.code?.length ? (
                    <span>
                      CODE:- <Tag>{promotion.code}</Tag>
                    </span>
                  ) : (
                    "AUTOMATIC"
                  );
                }}
              />
              <Table.Column
                title="Actions"
                render={(text, promotion: Promotion) => (
                  <Space>
                    <StatusToggleButton
                      currentStatus={promotion.active}
                      onSubmit={(active: boolean) =>
                        dispatch(updatePromotion({ id: promotion.id, active }))
                          .then(unwrapResult)
                          .then(() => loadPromotions(currentPage))
                      }
                    />
                    <Button
                      type="primary"
                      onClick={() => history.push(`/discounts/${promotion.id}/edit`)}
                    >
                      Edit
                    </Button>
                    <Button
                      type="primary"
                      onClick={() =>
                        Modal.confirm({
                          title: "Are you sure you want to delete this discount?",
                          icon: <ExclamationCircleOutlined />,
                          onOk: () => dispatch(deletePromotion(promotion)),
                          okText: "Delete",
                        })
                      }
                      danger
                    >
                      Delete
                    </Button>
                  </Space>
                )}
              />
            </Table>
            <div style={{ display: "flex", justifyContent: "right", padding: "15px 0" }}>
              <Pagination
                current={currentPage}
                total={total}
                pageSize={30}
                showSizeChanger={false}
                onChange={setCurrentPage}
              />
            </div>
          </Col>
        </Route>
        <Route path={`${path}/:id/edit`} component={EditPromotion} />
        <Route path={`${path}/new`} component={NewPromotion} />
      </Switch>
    </Row>
  );
};
