/* eslint-disable react-hooks/rules-of-hooks */
import { Checkbox, Input, InputNumber, Modal, Select } from "antd";
import React, { useState } from "react";

export const CustomItemModal = ({
  open,
  setOpen,
  CustomerId,
}: {
  open: boolean;
  setOpen: Function;
  CustomerId: number;
}) => {
  const [itemName, setItemName] = useState("");
  const [price, setPrice] = useState(0.0);
  const [quantity, setQuantity] = useState(1);
  const [taxable, setTaxable] = useState(true);
  const [weight, setWeight] = useState(0);
  const [unit, setUnit] = useState<"kg" | "lb" | "g" | "oz">("kg");
  const [shipping, setShipping] = useState(true);

  return (
    <Modal
      destroyOnClose
      visible={open}
      onCancel={() => {
        setOpen(false);
        setQuantity(1);
        setPrice(0);
        setUnit("kg");
        setShipping(true);
        setTaxable(true);
        setWeight(0);
        setItemName("");
      }}
      okText="Add item"
      title={"Add custom item"}
    >
      <div
        style={{ display: "grid", gridTemplateColumns: "repeat(2, minmax(0, 1fr))", gap: "1rem" }}
      >
        <div>
          <label>Item name</label>
          <Input
            value={itemName}
            onChange={(e) => {
              setItemName(e.currentTarget.value);
            }}
          />
        </div>

        <div style={{ display: "flex", gap: "1rem" }}>
          <div>
            <label>Price</label>
            <Input
              value={price}
              onChange={(e) =>
                setPrice(e.currentTarget.value === "" ? 0 : parseInt(e.currentTarget.value))
              }
              prefix={"$"}
            />
          </div>
          <div>
            <label>Quantity</label>
            <InputNumber
              value={quantity}
              onChange={(e) => setQuantity(parseInt(e?.toString() ?? "0"))}
            />
          </div>
        </div>
        <div
          style={{
            gridColumn: " span 2 / span 2",
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
          }}
        >
          <Checkbox
            checked={taxable}
            onChange={(e) => {
              setTaxable(e.target.checked);
            }}
          >
            Item is taxable
          </Checkbox>
          <Checkbox
            style={{ marginLeft: "0px" }}
            checked={shipping}
            onChange={(e) => {
              setShipping(e.target.checked);
            }}
          >
            Item requires shipping
          </Checkbox>
        </div>
        <Input.Group compact>
          <InputNumber
            value={weight}
            onChange={(e) => {
              setWeight(parseInt(e?.toString() ?? "0"));
            }}
          />

          <Select
            value={unit}
            onChange={setUnit}
            options={[
              { label: "g", value: "g" },
              { label: "kg", value: "kg" },
              { label: "lb", value: "lb" },
              { label: "oz", value: "oz" },
            ]}
          />
        </Input.Group>
      </div>
    </Modal>
  );
};
