import { unwrapResult } from "@reduxjs/toolkit";
import { Input, InputNumber, Modal, Radio, Select } from "antd";
import React, { useEffect, useState } from "react";
import { setLoading, setOrders } from "../../../../../store/draftSlice/createDraftSlice";
import { editOrder } from "../../../../../store/draftSlice/draftSlice";
import { useAppDispatch, useAppSelector } from "../../../../../store/store";

export const ShippingModal = ({ open, setOpen }: { open: boolean; setOpen: Function }) => {
  const orders = useAppSelector((state) => state.newdraft.orders);
  const [name, setName] = useState(
    orders?.shipping_line?.custom ? orders?.shipping_line?.title ?? "" : ""
  );
  const [handle, setHandle] = useState(
    `${orders?.shipping_line?.handle ?? "88f391cd101455e397458e9cc05bb9bf"},${
      orders?.shipping_line?.price ?? "0.00"
    },${orders?.shipping_line?.title ?? "Company Issue"}`
  );
  const [price, setPrice] = useState(
    orders?.shipping_line?.custom ? orders?.shipping_line?.price ?? "0" : "0"
  );
  const [option, setOption] = useState(orders?.shipping_line?.custom ? "custom" : "shipping");
  const dispatch = useAppDispatch();

  const shippingRates = [
    {
      value: "88f391cd101455e397458e9cc05bb9bf,0.00,Company Issue",
      label: "Company Issue $0.00",
    },
    {
      value: "809b8cc86800b7dffded9826e046f428,9.00,Fedex Ground",
      label: "Fedex Ground $9.00",
    },
  ];

  useEffect(() => {
    setHandle(
      orders?.shipping_line?.custom
        ? "88f391cd101455e397458e9cc05bb9bf,0.00,Company Issue"
        : `${orders?.shipping_line?.handle ?? "88f391cd101455e397458e9cc05bb9bf"},${
            orders?.shipping_line?.price ?? "0.00"
          },${orders?.shipping_line?.title ?? "Company Issue"}`
    );
    setName(orders?.shipping_line?.custom ? orders?.shipping_line?.title ?? "" : "");
    setPrice(orders?.shipping_line?.custom ? orders?.shipping_line?.price ?? "0" : "0");
    setOption(orders?.shipping_line?.custom ? "custom" : "shipping");
  }, [orders?.shipping_line]);

  return (
    <Modal
      destroyOnClose
      visible={open}
      onCancel={() => {
        setOpen(false);
        setName("");
        setPrice("0");
      }}
      onOk={() => {
        let data = { price, title: name, custom: option === "custom" };
        setOpen(false);
        dispatch(setLoading(true));
        setOpen(false);
        dispatch(
          editOrder({
            id: orders?.id ?? 0,
            data: {
              shipping_line: option === "custom" ? data : { ...data, handle: handle.split(",")[0] },
            },
          })
        )
          .then(unwrapResult)
          .then((res) => {
            dispatch(setOrders(res.data));
            dispatch(setLoading(false));
          });
      }}
      okButtonProps={{ disabled: option === "custom" && name === "" }}
      okText="Apply"
      title={"Shipping and delivery options"}
    >
      {/* <p style={{ color: "red" }}>
        If you're not seeing all your rates, add a customer with a complete shipping address.
      </p> */}
      <Radio.Group
        value={option}
        style={{ display: "flex", flexDirection: "column" }}
        onChange={(e) => setOption(e.target.value)}
      >
        <Radio value={"shipping"}>Shipping rates</Radio>
        <div style={{ height: "20px", fontSize: "12px", marginBottom: "4px", opacity: "50%" }}>
          &nbsp; Select eligible shipping rates based on your Shipping settings
        </div>
        {option === "shipping" && (
          <Select
            style={{ marginBottom: "0.5rem" }}
            options={shippingRates}
            value={handle}
            optionFilterProp={"label"}
            onChange={(e) => {
              let temp = e.split(",");
              setHandle(e);
              setName(temp[2]);
              setPrice(temp[1]);
            }}
          />
        )}

        <Radio value={"custom"}>Custom</Radio>
      </Radio.Group>
      {option === "custom" && (
        <div
          style={{
            display: "grid",
            gap: "1rem",
            gridTemplateColumns: "repeat(2, minmax(0, 1fr))",
          }}
        >
          {" "}
          <div>
            <label>Name</label>
            <Input
              placeholder="eg: Free shipping"
              value={name}
              onChange={(e) => setName(e.currentTarget.value)}
            />
          </div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <label>Price</label>
            <InputNumber
              style={{ width: "100%" }}
              value={parseFloat(price.toString())}
              onChange={(e) => setPrice(e ?? "0.00")}
              formatter={(value) => `$ ${value}`}
            />
          </div>
        </div>
      )}
    </Modal>
  );
};
