import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { RootState, useAppDispatch } from "../../store/store";
import { useSelector } from "react-redux";
import {
  getDefaultCompanyIssues,
  pricingListSelectors,
  resetDefaultCompanyIssues,
  updateDefaultCompanyIssues,
} from "../../store/pricingLists/pricingListSlice";
import {
  Breadcrumb,
  Button,
  Checkbox,
  Col,
  Form,
  InputNumber,
  Modal,
  PageHeader,
} from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useForm } from "antd/es/form/Form";
import { DefaultCompanyIssue } from "../../interfaces/DefaultCompanyIssue";
import { unwrapResult } from "@reduxjs/toolkit";

// const layout = {
//   labelCol: { span: 10 },
//   wrapperCol: { span: 2 },
// };

export const CompanyIssues = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { pricingListId } = useParams();
  const pricingList = useSelector((state: RootState) =>
    pricingListSelectors.selectById(state, pricingListId)
  );
  const [companyIssues, setCompanyIssues] = useState<DefaultCompanyIssue[]>([]);
  useEffect(() => {
    dispatch(getDefaultCompanyIssues(pricingListId))
      .then(unwrapResult)
      .then((issues) => {
        setCompanyIssues(issues);
        form.setFieldsValue({ issues });
      });
  }, []);

  const [form] = useForm();
  const [formStateActive, setFormStateActive] = useState(false);
  const onSubmit = async () => {
    const formData = form.getFieldsValue();
    setFormStateActive(true);
    await dispatch(updateDefaultCompanyIssues({ data: formData.issues, pricingListId })).then(
      unwrapResult
    );
    setFormStateActive(false);
  };

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item key="dashboard">Dashboard</Breadcrumb.Item>
        <Breadcrumb.Item key="pricingLists">Pricing Lists</Breadcrumb.Item>
        <Breadcrumb.Item key={pricingListId}>{pricingList?.title}</Breadcrumb.Item>
        <Breadcrumb.Item key="company-issues">Company Issues</Breadcrumb.Item>
      </Breadcrumb>
      <Col sm={24}>
        <PageHeader
          title="Company Issue"
          className="site-page-header"
          onBack={() => history.push(`/pricingLists/${pricingListId}`)}
          extra={
            <Button
              type="primary"
              onClick={() =>
                Modal.confirm({
                  title:
                    "Are you sure you want to reset Company Issue of all customeres from this pricing list?",
                  icon: <ExclamationCircleOutlined />,
                  onOk: () => dispatch(resetDefaultCompanyIssues(pricingListId)),
                  okText: "Yes",
                  okButtonProps: { danger: true },
                })
              }
              danger
            >
              Reset Company Issues
            </Button>
          }
        />
      </Col>
      <Col span={20}>
        <Form form={form} onFinish={onSubmit}>
          <Form.List name={"issues"}>
            {(issues) => (
              <div>
                {issues.map((i, index: number) => (
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <Form.Item
                      initialValue={companyIssues[i.key].quantity}
                      label={companyIssues[i.key].productType}
                      name={[i.name, "quantity"]}
                      style={{ width: "60%" }}
                      labelCol={{ span: 10 }}
                      wrapperCol={{ span: 12 }}
                    >
                      <InputNumber />
                    </Form.Item>
                    <Form.Item
                      label="Mandatory"
                      name={[i.name, "mandatory"]}
                      style={{ width: "50%" }}
                      labelCol={{ span: 5 }}
                      wrapperCol={{ span: 4 }}
                      initialValue={companyIssues[i.key].mandatory}
                      valuePropName="checked"
                    >
                      <Checkbox />
                    </Form.Item>
                  </div>
                ))}
              </div>
            )}
          </Form.List>
          <Form.Item wrapperCol={{ span: 14, offset: 1 }}>
            <Button type="primary" htmlType="submit" loading={formStateActive}>
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Col>
    </>
  );
};
