import { unwrapResult } from "@reduxjs/toolkit";
import { Button, Form, Input, InputNumber, Modal, Select } from "antd";
import { floor } from "lodash";
import React, { useEffect } from "react";
import { useState } from "react";
import { draft, LineItem } from "../../../../interfaces/Drafts";
import { setLoading, setOrders } from "../../../../store/draftSlice/createDraftSlice";
import { editOrder } from "../../../../store/draftSlice/draftSlice";
import { useAppDispatch, useAppSelector } from "../../../../store/store";

export const DiscountModal = ({
  open,
  setOpen,
  lineItem,
  setLineItems = () => {},
}: {
  open: boolean;
  setOpen: Function;
  setLineItems?: Function;
  lineItem?: number;
}) => {
  const [discountType, setDiscountType] = useState("fixed_amount");
  const loading = useAppSelector((state) => state.newdraft.loading);
  const orders = useAppSelector((state) => state.newdraft.orders);
  const [discount, setDiscount] = useState(0);
  const [discountData, setDiscountData] = useState<draft | LineItem>();
  const [discountMessage, setDiscountMessage] = useState("");
  const dispatch = useAppDispatch();

  useEffect(() => {
    let data = lineItem
      ? orders?.line_items.find((x) => x.id === lineItem)?.applied_discount
      : orders?.applied_discount;

    setDiscountData(lineItem ? orders?.line_items.find((x) => x.id === lineItem) : orders);

    console.log({
      discount: data,
      test: parseInt(data?.amount ?? "0"),
    });

    setDiscount(parseFloat(data?.value ?? "0"));
    setDiscountMessage(data?.description ?? "");
    setDiscountType(data?.value_type ?? "fixed_amount");
  }, [orders?.applied_discount, lineItem]);

  return (
    <Modal
      destroyOnClose
      visible={open}
      onCancel={() => {
        setOpen(false);
        setLineItems(undefined);
        setDiscountMessage(orders?.applied_discount?.description ?? "");
        setDiscount(parseFloat(orders?.applied_discount?.value ?? "0"));
        setDiscountType(orders?.applied_discount?.value_type ?? "fixed_amount");
      }}
      footer={
        <div style={{ display: "flex", justifyContent: "end", gap: "4px" }}>
          <Button
            onClick={() => {
              setLineItems(undefined);
              setOpen(false);
              setDiscountMessage(orders?.applied_discount?.description ?? "");
              setDiscount(parseFloat(orders?.applied_discount?.amount ?? "0"));
              setDiscountType("fixed_amount");
            }}
          >
            Cancel
          </Button>
          {(orders?.applied_discount || (lineItem && discountData?.applied_discount)) && (
            <Button
              type="primary"
              style={{ background: "red", border: "None" }}
              onClick={() => {
                let data =
                  lineItem && orders?.line_items
                    ? {
                        line_items: [
                          ...orders?.line_items?.map((y) =>
                            y.id !== lineItem
                              ? y
                              : {
                                  ...orders?.line_items.find((y) => y.id === lineItem),
                                  applied_discount: null,
                                }
                          ),
                        ],
                        applied_discount: null,
                      }
                    : {
                        applied_discount: null,
                      };
                dispatch(setLoading(true));
                orders?.id &&
                  dispatch(
                    editOrder({
                      id: orders.id,
                      data,
                    })
                  )
                    .then(unwrapResult)
                    .then((res) => {
                      dispatch(setOrders(res.data));
                      dispatch(setLoading(false));
                    });
                setLineItems(undefined);
                setOpen(false);
              }}
            >
              Remove Discount
            </Button>
          )}

          <Button
            type="primary"
            onClick={() => {
              dispatch(setLoading(true));
              orders?.id &&
                (lineItem
                  ? dispatch(
                      editOrder({
                        id: orders.id,
                        data: {
                          line_items: [
                            ...orders?.line_items.map((y) =>
                              y.id !== lineItem
                                ? y
                                : {
                                    ...orders?.line_items.find((y) => y.id === lineItem),
                                    applied_discount: {
                                      description: discountMessage,
                                      value_type: discountType,
                                      value: discount,
                                      amount:
                                        discountType === "fixed_amount"
                                          ? discount
                                          : floor(parseInt(orders.total_price ?? "0") * discount) /
                                            100,
                                      title: "Custom",
                                    },
                                  }
                            ),
                          ],
                          applied_discount: null,
                        },
                      })
                    )
                  : dispatch(
                      editOrder({
                        id: orders.id,
                        data: {
                          applied_discount: {
                            description: discountMessage,
                            value_type: discountType,
                            value: discount,
                            amount:
                              discountType === "fixed_amount"
                                ? discount
                                : floor(parseInt(orders.total_price ?? "0") * discount) / 100,
                            title: "Custom",
                          },
                        },
                      })
                    )
                )
                  .then(unwrapResult)
                  .then((res) => {
                    dispatch(setOrders(res.data));
                    dispatch(setLoading(false));
                  });
              setLineItems(undefined);
              setOpen(false);
            }}
          >
            Apply
          </Button>
        </div>
      }
      title={"Add Discount"}
    >
      <div
        style={{ display: "grid", gridTemplateColumns: "repeat(2, minmax(0, 1fr))", gap: "1rem" }}
      >
        <div>
          <div>Discount type</div>
          <Select
            value={discountType}
            style={{ width: "100%" }}
            onChange={(e) => {
              setDiscount(0);
              setDiscountType(e);
            }}
            options={[
              { label: "Amount", value: "fixed_amount" },
              { label: "Percentage", value: "percentage" },
            ]}
          />
        </div>
        <div style={{ display: "flex", flexDirection: "column" }}>
          {" "}
          <label htmlFor="">Discount value</label>{" "}
          <InputNumber
            style={{ width: "100%" }}
            value={discount}
            max={
              discountType === "percentage"
                ? 100
                : parseFloat(
                    (lineItem
                      ? (discountData as LineItem)?.price
                      : (discountData as draft)?.subtotal_price) ??
                      "0" + parseFloat(discountData?.applied_discount?.amount ?? "0")
                  )
            }
            onChange={(e) => setDiscount(e === "" ? 0 : parseFloat(e?.toString() ?? "0"))}
            formatter={(value) => (discountType === "percentage" ? `${value}%` : `$ ${value}`)}
          />
        </div>
        <div style={{ gridColumn: " span 2 / span 2" }}>
          <label htmlFor="">Reason for discount</label>
          <Input
            value={discountMessage}
            onChange={(e) => setDiscountMessage(e.currentTarget.value)}
          />
          <p style={{ opacity: "50%" }}>Your customers can see this reason</p>
        </div>
      </div>
    </Modal>
  );
};
