import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { RootState, useAppDispatch } from "../../store/store";
import { useSelector } from "react-redux";
import {
  getDefaultExemptStates,
  getDefaultExemptStatus,
  pricingListSelectors,
  resetDefaultTaxExemption,
  updateDefaultTaxExemption,
} from "../../store/pricingLists/pricingListSlice";
import { Breadcrumb, Button, Checkbox, Col, Form, Modal, PageHeader, Switch } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useForm } from "antd/es/form/Form";
import { unwrapResult } from "@reduxjs/toolkit";
import { DefaultExemptState } from "../../interfaces/DefaultExemptState";
import type { CheckboxChangeEvent } from "antd/es/checkbox";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { CheckboxValueType } from "antd/es/checkbox/Group";

// const layout = {
//   labelCol: { span: 10 },
//   wrapperCol: { span: 2 },
// };

export const TaxExemption = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { pricingListId } = useParams();
  const pricingList = useSelector((state: RootState) =>
    pricingListSelectors.selectById(state, pricingListId)
  );
  const [exemptStatusLoading, setExemptStatusLoading] = useState(true);
  const [exemptStatus, setExemptStatus] = useState(false);

  // fetched data
  const [taxExemptionStatesListLoading, setTaxExemptionStatesListLoading] = useState(true);
  const [taxExemptionStatesList, setTaxExemptionStatesList] = useState<DefaultExemptState[]>([]);

  const [checkedListOptions, setCheckedListOptions] = useState<string[]>([]);
  const [checkedSelectedList, setCheckedSelectedList] = useState<CheckboxValueType[]>([]);
  const [checkIndeterminate, setCheckIndeterminate] = useState(true);
  const [checkSelectAll, setCheckSelectAll] = useState(false);

  const onCheckedListChange = (list: Array<CheckboxValueType>) => {
    setCheckedSelectedList(list);
    setCheckIndeterminate(!!list.length && list.length < checkedListOptions.length);
    setCheckSelectAll(list.length === checkedListOptions.length);
  };

  const onCheckSelectAllChange = (e: CheckboxChangeEvent) => {
    setCheckedSelectedList(e.target.checked ? checkedListOptions : []);
    setCheckIndeterminate(false);
    setCheckSelectAll(e.target.checked);
  };

  useEffect(() => {
    setTaxExemptionStatesListLoading(true);
    dispatch(getDefaultExemptStates(pricingListId))
      .then(unwrapResult)
      .then((states) => {
        console.log({ lol: states });

        setTaxExemptionStatesList(states);

        const allList: string[] = [];
        const selected: CheckboxValueType[] = [];
        states.map((e) => {
          allList.push(e.stateNameOrCode);
          if (e.isExempt) selected.push(e.stateNameOrCode);
          return e;
        });
        setCheckedListOptions([...allList]);
        setCheckedSelectedList([...selected]);
      })
      .finally(() => setTaxExemptionStatesListLoading(false));
  }, []);

  useEffect(() => {
    console.log({ checkedListOptions });
  }, [checkedListOptions]);

  useEffect(() => {
    setExemptStatusLoading(true);
    dispatch(getDefaultExemptStatus(pricingListId))
      .then(unwrapResult)
      .then((status) => {
        console.log({ status });
        setExemptStatus(status);
        console.log(form.getFieldValue("isTaxExempt"), exemptStatus);
        form.setFieldsValue({ isTaxExempt: status });
      })
      .finally(() => setExemptStatusLoading(false));
  }, []);

  const [form] = useForm();
  const [formSubmitLoading, setFormSubmitLoading] = useState(false);
  const onSubmit = async () => {
    setFormSubmitLoading(true);
    const data = taxExemptionStatesList.map((e) => {
      return {
        ...e,
        taxExempt: !!(
          checkedSelectedList.filter((checkedVal) => checkedVal === e.stateNameOrCode).length > 0
        ),
      };
    });

    const submitData = {
      data,
      pricingListId,
      isTaxExempt: exemptStatus,
    };
    console.log({ submitData, checkedSelectedList });

    await dispatch(updateDefaultTaxExemption(submitData))
      .then(unwrapResult)
      .then((states) => {
        console.log(states);
      })
      .finally(() => setFormSubmitLoading(false));
  };

  const onResetRequest = async () => {
    setExemptStatus(false);
    setCheckedSelectedList([]);
    setCheckIndeterminate(false);
    setCheckSelectAll(false);
  };

  const handleOnResetFn = async () => {
    try {
      await dispatch(resetDefaultTaxExemption(pricingListId));
      onResetRequest();
    } catch (error) {
      console.log({ resetDefaultTaxExemptionError: error });
    }
  };

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item key="dashboard">Dashboard</Breadcrumb.Item>
        <Breadcrumb.Item key="pricingLists">Pricing Lists</Breadcrumb.Item>
        <Breadcrumb.Item key={pricingListId}>{pricingList?.title}</Breadcrumb.Item>
        <Breadcrumb.Item key="tax-exempt">Tax Exempt</Breadcrumb.Item>
      </Breadcrumb>
      <Col sm={24}>
        <PageHeader
          title="Tax Exemption"
          className="site-page-header"
          onBack={() => history.push(`/pricingLists/${pricingListId}`)}
          extra={
            <Button
              type="primary"
              loading={taxExemptionStatesListLoading}
              onClick={() =>
                Modal.confirm({
                  title:
                    "Are you sure you want to reset Tax exemption of entire company and states",
                  icon: <ExclamationCircleOutlined />,
                  onOk: handleOnResetFn,
                  okText: "Yes",
                  okButtonProps: { danger: true },
                })
              }
              danger
            >
              Reset Tax Exemption
            </Button>
          }
        />
      </Col>

      {taxExemptionStatesListLoading ? (
        <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
      ) : (
        <div style={{ width: "100%", textAlign: "left" }}>
          <Col sm={24}>
            <PageHeader
              title="Exempt Client associated with Price List from taxes"
              className="site-page-header"
              extra={
                <Form>
                  <Form.Item label="Tax Exempt" name="isTaxExempt">
                    <Switch
                      loading={exemptStatusLoading}
                      checked={exemptStatus}
                      onClick={() => setExemptStatus((oldValue) => !oldValue)}
                      style={{ scale: 1.1 }}
                    />
                  </Form.Item>
                </Form>
              }
            />
          </Col>
          <Col span={20} style={{ margin: "0 5rem 2rem" }}>
            <Checkbox
              indeterminate={checkIndeterminate}
              checked={checkSelectAll}
              onChange={onCheckSelectAllChange}
              style={{ fontSize: "1.2rem" }}
            >
              Select All States
            </Checkbox>

            <Checkbox.Group
              options={checkedListOptions}
              value={checkedSelectedList}
              onChange={onCheckedListChange}
              style={{ display: "flex", flexDirection: "column", gap: "0.5rem", margin: "1rem 0" }}
            />

            <Button type="primary" loading={formSubmitLoading} onClick={onSubmit}>
              Submit
            </Button>
          </Col>
        </div>
      )}
    </>
  );
};
