import { Order } from "../../interfaces/Order";
import { draftStatus, VerificationStatus } from "../../enums/VerificationStatus";
import { Button, Space, Table, Tag } from "antd";
import { DateTime } from "luxon";
import React, { useState } from "react";
import { Customer } from "../../interfaces/Customer";
import { Link, useHistory } from "react-router-dom";
import _ from "lodash";
import { draft, draftTable } from "../../interfaces/Drafts";

export const DraftsTable = ({ drafts, loading }: { drafts: draftTable[]; loading?: boolean }) => {
  const history = useHistory();
  console.log({ drafts });

  const verificationStatusTagColors = {
    [draftStatus.OPEN]: "#108ee9",
    [draftStatus.COMPELETED]: "#87d068",
  };

  return (
    <>
      <Table
        dataSource={drafts}
        rowKey="id"
        pagination={false}
        loading={loading}
        scroll={{ x: 1300 }}
      >
        <Table.Column
          title="Draft order"
          dataIndex="name"
          fixed="left"
          width={120}
          render={(name, draft: any) => <Link to={`/drafts/${draft.id}`}> {name}</Link>}
        />
        <Table.Column
          title="Status"
          dataIndex="status"
          key="status"
          fixed="left"
          width={170}
          render={(status: draftStatus) => (
            <Tag color={verificationStatusTagColors[status]}>{status?.toUpperCase()}</Tag>
          )}
        />
        <Table.Column title="Note" dataIndex="note" key="note" width={350} />

        <Table.Column
          title="Invoice Sent At"
          dataIndex="invoiceSentAt"
          key="invoiceSentAt"
          // width={180}
          render={(date) =>
            date && date === "notsend" ? (
              <Tag>Not Sent</Tag>
            ) : (
              DateTime.fromISO(date).toLocaleString(DateTime.DATETIME_MED)
            )
          }
        />
        <Table.Column
          title="Created At"
          dataIndex="createdAt"
          key="createdAt"
          // width={180}
          render={(date) => date && DateTime.fromISO(date).toLocaleString(DateTime.DATETIME_MED)}
        />
        <Table.Column
          title="Actions"
          render={(text, order: draftTable) => (
            <Space direction="horizontal" align="end" >
              {order.status !== draftStatus.COMPELETED && (
                <Button
                  type="primary"
                  target="_blank"
                  onClick={() => {
                    // dispatch(setOrders(order));
                    history.push(`/orders/edit/${order.id}`);
                  }}
                >
                  Edit
                </Button>
              )}
              <Button
                type="primary"
                onClick={() =>
                  history.push(`/drafts/${order.id}`, { state: { invoice: order.invoiceUrl } })
                }
              >
                View
              </Button>
              <Button
                type="primary"
                href={`${process.env.REACT_APP_SHOP_URL}/admin/draft_orders/${order.id}`}
                target="_blank"
              >
                View In Shopify
              </Button>
            </Space>
          )}
        />
      </Table>
    </>
  );
};
