import { useEffect, useState } from "react";
import validator from "validator";
// import PhoneInput from "react-phone-input-2";
import PI from "react-phone-input-2";
import { Skeleton } from "antd";
import React from "react";

export const Phoneinput = ({
  phone,
  setPhone,
  mobileValidation,
  label,
  country = "in",
  phoneInputError,
  loading = false,
  setPhoneInputError,
}: {
  label: any;
  phone: string;
  country?: string;
  setPhone: Function;
  phoneInputError: string;
  setPhoneInputError: Function;
  loading?: boolean;
  mobileValidation: React.MutableRefObject<boolean>;
}) => {
  const [areaCodeLength, setAreaCodeLength] = useState(0);
  const ReactPhoneInput = (PI as any).default ? (PI as any).default : PI;
  console.log("phoneInputError", phoneInputError);
  function handlePhoneInputOnBlur(
    value: React.FocusEvent<HTMLInputElement>,
    country: { countryCode: string }
  ): void {
    // const countryCode = country.countryCode.toLocaleUpperCase();
    // const localeWithCountryCode = validator.isMobilePhoneLocales.find((item) =>
    // 	item.includes(countryCode)
    // );
    // const formattedPhone = phone.slice(areaCodeLength + 2);
    // const isValidMobileNumber = phone?.length>8
  }

  function handlephoneOnFocus() {
    setPhoneInputError("");
  }

  useEffect(() => {
    console.log({ phone, length: phone.length });
    phone?.length >= 8 ? setPhoneInputError("") : setPhoneInputError("Invalid Mobile Number");
    mobileValidation.current = phone?.length < 8;
  }, [phone]);

  function handlePhoneOnChange(
    phoneNumber: string,
    country: { countryCode: string; dialCode: string }
  ) {
    setAreaCodeLength(country.dialCode?.length);
    setPhone(() => `+${country.dialCode + " " + phoneNumber.slice(country.dialCode?.length)}`);
    // const countryCode = country.countryCode.toLocaleUpperCase();

    // const localeWithCountryCode = validator.isMobilePhoneLocales.find((item) =>
    // 	item.includes(countryCode)
    // );
    // const formattedPhone = phoneNumber.slice(areaCodeLength);
  }

  return (
    <div>
      <div>{label}</div>

      <div className="  w-[80%]">
        {loading ? (
          <Skeleton.Input />
        ) : (
          <ReactPhoneInput
            inputProps={{
              name: "mobile",
              autoFocus: false,
            }}
            inputStyle={{
              fontSize: "13px",
              color: "black",
              height: "40px",
              width: "100%",
              fontWeight: 400,
              borderRadius: "0px",
              //   border: "1px solid rgb(209 213 219)",
              border: `${phoneInputError ? "1px solid red" : "1px solid rgb(209 213 219)"}`,
            }}
            // className={
            //   phoneInputError ? "border border-red" : "border border-green"
            // }
            country={country}
            value={phone}
            preferredCountries={[country]}
            placeholder=""
            onChange={handlePhoneOnChange}
            onBlur={handlePhoneInputOnBlur}
            onFocus={handlephoneOnFocus}
          />
        )}
        <span
          style={{ color: "red" }}
          className={`text-sm font-medium text-red ml-2  ${phoneInputError ? "inline" : "hidden"}`}
        >
          {phoneInputError ? phoneInputError : null}
        </span>
      </div>
    </div>
  );
};
