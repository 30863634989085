import { Checkbox, Divider, Input, Modal, Select } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { Phoneinput } from "../../../../../components/phoneInput";
// import { Countries } from "../../../../shared/countryList";
import "react-country-state-city/dist/react-country-state-city.css";
import { countries } from "../../../../../shared/countries";
import { states } from "../../../../../shared/states";
import form, { Rule } from "antd/lib/form";
import {
  Address,
  CustomerDetailsDataType,
} from "../../../../../store/customersSlice/customersSlice";
import { useAppDispatch, useAppSelector } from "../../../../../store/store";
import { setCustomer } from "../../../../../store/draftSlice/createDraftSlice";

export const InputComponent = ({
  label,
  state,
  setState,
  rules,
  maxLength
}: {
  label: string;
  state: string;
  setState: Function;
  rules?: Rule[];
  maxLength?:number
}) => {
  return (
    <div>
      <label>{label}</label>
      {rules ? (
        <form.Item  rules={rules} validateTrigger="onChange">
          <Input
          maxLength={maxLength}
            value={state}
            onChange={(e) => {
              setState(e.currentTarget.value);
            }}
          />
        </form.Item>
      ) : (
        <Input
          maxLength={maxLength}
          style={{ marginBottom: "24px" }}
          value={state}
          onChange={(e) => {
            setState(e.currentTarget.value);
          }}
        />
      )}
    </div>
  );
};

export const CreateCustomerModal = ({ open, setOpen }: { open: boolean; setOpen: Function }) => {
  const customer = useAppSelector((state) => state.newdraft.customer);
  const billingAddress = useAppSelector((state) => state.newdraft.billingAddress);
  const shippingAddress = useAppSelector((state) => state.newdraft.shippingAddress);
  const [fName, setFName] = useState(customer?.first_name ?? "");
  const [lName, setLName] = useState(customer?.last_name ?? "");
  const [company, setCompany] = useState(customer?.default_address?.company ?? "");
  const [emailMarketing, setEmailMarketing] = useState(
    customer?.email_marketing_consent?.state === "subscribed"
  );
  const [taxExemption, setTaxExemption] = useState(customer?.tax_exempt ?? false);
  const [addressLine1, setAddressLine1] = useState(customer?.default_address?.address1 ?? "");
  const [addressLine2, setAddressLine2] = useState(customer?.default_address?.address2 ?? "");
  const [zipCode, setZipCode] = useState(customer?.default_address?.zip ?? "");
  const [phone, setPhone] = useState(customer?.phone ?? "");
  const [phoneInputError, setPhoneInputError] = useState("");
  const [city, setCity] = useState(customer?.default_address?.city ?? "");
  const [stateList, setStateList] = useState<{ label: string; value: string }[]>([]);
  const [state, setState] = useState(customer?.default_address?.province ?? "");
  const [country, setCountry] = useState(customer?.default_address?.country_code ?? "US");
  const mobileValidation = useRef(true);
  const dispatch = useAppDispatch();
  useEffect(() => {
    console.log({ country });

    setStateList([]);
    states
      .find((x) => x.name === countries.find((x) => x.value === country)?.label)
      ?.states.map((x) => setStateList((old) => [...old, { label: x.name, value: x.name }]));
  }, [country]);

  return (
    <Modal
      destroyOnClose
      visible={open}
      onCancel={() => {
        setOpen(false);
        setFName("");
        setLName("");
        setCompany("");
        setEmailMarketing(false);
        setTaxExemption(false);
        setAddressLine1("");
        setAddressLine2("");
        setZipCode("");
        setPhone("");
        setPhoneInputError("");
        setCity("");
        setStateList([]);
        setState("");
        setCountry("United States");
      }}
      style={{ width: "50vw" }}
      okText="Save customer"
      onOk={() => {
        // dispatch(setCustomer({ id: number,
        //   email,
        //   accepts_marketing: boolean,
        //   created_at: string,
        //   updated_at: string,
        //   first_name: string,
        //   last_name: string,
        //   orders_count: number,
        //   state: string,
        //   total_spent: string,
        //   last_order_id: number,
        //   note: string | null,
        //   verified_email: boolean,
        //   multipass_identifier: string | null,
        //   tax_exempt: boolean,
        //   tags: string,
        //   last_order_name: string,
        //   currency: string,
        //   phone: string | null,
        //   addresses: Address[],
        //   accepts_marketing_updated_at: string,
        //   marketing_opt_in_level: string | null,
        //   tax_exemptions: any[],
        //   email_marketing_consent: EmailMarketingConsent,
        //   sms_marketing_consent: null,
        //   admin_graphql_api_id: string;,
        //   default_address: Address}));
        setOpen(false);
      }}
      title={"Create a new Customer"}
    >
      <div style={{ maxHeight: "60vh", overflowY: "scroll", margin: "-24px", padding: "24px" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            gap: "8px",
          }}
        >
          <InputComponent label={"First name"} state={fName} setState={setFName} />
          <InputComponent label={"Last name"} state={lName} setState={setLName} />
        </div>
        <div style={{ marginBottom: "24px" }}>
          <div>Language</div>
          <Select
            defaultValue={"english"}
            style={{ width: "100%", marginBottom: "4px" }}
            options={[{ label: "English [Default]", value: "english" }]}
          />
          <div style={{ opacity: "50%" }}>
            This customer will receive notifications in this language.
          </div>
        </div>

        <Checkbox
          style={{ marginBottom: "8px" }}
          checked={emailMarketing}
          onChange={(e) => {
            setEmailMarketing(e.target.checked);
          }}
        >
          Customer accepts email marketing
        </Checkbox>
        <Checkbox
          style={{ marginLeft: "0px" }}
          checked={taxExemption}
          onChange={(e) => {
            setTaxExemption(e.target.checked);
          }}
        >
          Customer is tax exempt
        </Checkbox>

        <Divider />

        <div style={{ fontWeight: "bold", marginBottom: "8px" }}>Shipping address</div>

        <div style={{ marginBottom: "24px" }}>
          <div>Country/region</div>
          <Select
            showSearch
            defaultValue={country}
            style={{ width: "100%" }}
            options={countries}
            optionFilterProp={"label"}
            onChange={(e) => {
              setCountry(e);
            }}
          />
        </div>
        <InputComponent label={"Company"} state={company} setState={setCompany} />
        <InputComponent label={"Address"} state={addressLine1} setState={setAddressLine1} />

        <InputComponent
          label={"Apartment, suite, etc."}
          state={addressLine2}
          setState={setAddressLine2}
        />
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            gap: "8px",
          }}
        >
          <InputComponent label={"City"} state={city} setState={setCity} />
          <div style={{ width: "100%" }}>
            <div>State</div>
            <Select
              showSearch
              defaultValue={state}
              style={{ width: "100%" }}
              options={[...stateList]}
              onChange={(e) => {
                setState(e);
              }}
            />
          </div>
        </div>

        <InputComponent label={"ZIP code"} state={zipCode} setState={setZipCode} />
        <Phoneinput
          label={"Phone"}
          phone={phone}
          country={country.toLowerCase()}
          setPhone={setPhone}
          phoneInputError={phoneInputError}
          setPhoneInputError={setPhoneInputError}
          mobileValidation={mobileValidation}
        />
      </div>
    </Modal>
  );
};
