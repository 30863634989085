/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Card,
  Checkbox,
  DatePicker,
  Divider,
  Dropdown,
  Menu,
  message,
  Modal,
  Select,
  Skeleton,
} from "antd";
import moment, { Moment } from "moment";
import React, { useEffect, useRef, useState } from "react";
import { IoIosArrowDown } from "react-icons/io";
import { Link, useHistory } from "react-router-dom";
import { DiscountModal } from "../modals/discountModal";
import { ShippingModal } from "../modals/Payments/shippingModal";
import { TaxDropdown } from "../modals/Payments/taxDropdown";
import { TaxRateModal } from "../modals/Payments/taxRateModal";
import { useAppDispatch, useAppSelector } from "../../../../store/store";
import {
  compeleteOrder,
  editOrder,
  saveDraft,
  sendInvoice,
} from "../../../../store/draftSlice/draftSlice";
import { resetState, setLoading } from "../../../../store/draftSlice/createDraftSlice";
import { InvoiceModal } from "../modals/Payments/invoiceModal";
import { Money } from "../../../../components/Money";
import { clearPaginatedDraft } from "../../../../store/draftSlice/draftPaginationSlice";

export const PaymentsCard = ({ setUnsaved }: { setUnsaved: Function }) => {
  const date = new Date();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const [payLater, setPayLater] = useState(false);
  const [invoice, setInvoice] = useState(false);
  const [editShipping, setEditShipping] = useState(false);
  const [taxation, setTaxation] = useState(false);
  const [shippingError, setShippingError] = useState(false);
  const [markAsPaid, setMarkAsPaid] = useState(false);
  const [showTax, setShowTax] = useState(false);
  const [paymentDue, setPaymentDue] = useState("Due on Receipt");
  const [DueDateMessage, setDueDateMessage] = useState("Payment due when invoice is sent.");
  const [fixedDate, setFixedDate] = useState<Moment>(moment(date.setDate(date.getDate())));
  const [addingDiscount, setAddingDiscount] = useState(false);
  const wrapperRef: any = useRef(null);
  const loading = useAppSelector((state) => state.newdraft.loading);
  const orders = useAppSelector((state) => state.newdraft.orders);
  // const selectedCustomerdata = useAppSelector((state) => state.newdraft.customer);

  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event: { target: any }) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        taxation && setTaxation(false);
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);

  useEffect(() => {
    dispatch(resetState());
  }, []);

  useEffect(() => {
    switch (paymentDue) {
      case "Due on Receipt":
        setDueDateMessage("Payment due when invoice is sent.");
        break;
      case "Due on Fulfillment":
        setDueDateMessage("Payment due when order is fulfilled.");
        break;
      case "Fixed date":
        setDueDateMessage(`Payment due on ${moment(fixedDate).format("MMMM D, yyyy")}.`);
        break;
      default:
        date.setDate(date.getDate() + parseInt(paymentDue));
        setDueDateMessage(
          `Payment due on ${moment(date).format("MMMM D, yyyy")}   (Net ${paymentDue}).`
        );
        break;
    }
  }, [paymentDue]);

  return (
    <>
      <Modal
        visible={shippingError}
        onCancel={() => {
          setShippingError(false);
        }}
        title="Shipping Error"
        okText="OK"
        onOk={() => {
          setShippingError(false);
        }}
      >
        Your shipping Address is missing state name, kindly update your shipping address.
      </Modal>
      <Modal
        visible={markAsPaid}
        onCancel={() => {
          setMarkAsPaid(false);
        }}
        title="Mark as paid"
        okText="Create Order"
        onOk={() => {
          setMarkAsPaid(false);
          dispatch(setLoading(true));
          dispatch(saveDraft({ id: orders?.id ?? 0, paid: false }))
            .then(() => {
              dispatch(resetState());
              dispatch(clearPaginatedDraft());
            })
            .then(() => history.go(-1));
        }}
      >
        <p>
          This will create an order. Mark this order as paid if you received ${orders?.total_price}{" "}
          outside of Shopify.
        </p>
        <Select
          style={{ width: "100%" }}
          defaultValue={"other"}
          options={[
            { value: "other", label: "other" },
            { value: "M&H payment method", label: "M&H Payment Method" },
          ]}
        />
      </Modal>
      {/* <InvoiceModal open={invoice} setOpen={setInvoice} /> */}
      <TaxRateModal open={showTax} setOpen={setShowTax} />
      <ShippingModal open={editShipping} setOpen={setEditShipping} />
      <DiscountModal open={addingDiscount} setOpen={setAddingDiscount} />{" "}
      <Card>
        <p style={{ textAlign: "left", marginBottom: "1.5rem" }}>Payment</p>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(3, minmax(0, 1fr))",
            padding: "0px 8px",
          }}
        >
          <p style={{ textAlign: "left", gridColumn: " span 2 / span 2" }}>Subtotal</p>
          {loading ? (
            <Skeleton.Input active={loading} style={{ height: "18px", marginRight: "8px" }} />
          ) : (
            <p style={{ textAlign: "right" }}>
              <Money
                value={
                  orders?.subtotal_price
                    ? (parseFloat(orders?.subtotal_price) +
                        parseFloat(orders?.applied_discount?.amount ?? "0")) *
                      100
                    : 0
                }
              />
            </p>
          )}

          {loading ? (
            <Skeleton.Input active={loading} style={{ height: "18px", marginRight: "8px" }} />
          ) : (
            <Link
              style={{ textAlign: "left" }}
              to=""
              onClick={(e) => {
                e.preventDefault();
                orders?.id && setAddingDiscount(true);
              }}
            >
              {orders?.applied_discount ? "Update discount" : "Add discount"}
            </Link>
          )}
          {loading ? (
            <Skeleton.Input active={loading} style={{ height: "18px", marginRight: "8px" }} />
          ) : (
            <p style={{ textAlign: "left" }}>{orders?.applied_discount?.description ?? "-"}</p>
          )}
          {loading ? (
            <Skeleton.Input active={loading} style={{ height: "18px", marginRight: "8px" }} />
          ) : (
            <p style={{ textAlign: "right" }}>
              -
              <Money value={parseFloat(orders?.applied_discount?.amount ?? "0.00") * 100} />
            </p>
          )}

          {loading ? (
            <Skeleton.Input active={loading} style={{ height: "18px", marginRight: "8px" }} />
          ) : (
            <Link
              style={{ textAlign: "left" }}
              to=""
              onClick={(e) => {
                e.preventDefault();
                orders?.shipping_address.province
                  ? setEditShipping(true)
                  : orders?.id && setShippingError(true);
              }}
            >
              Add shipping or delivery
            </Link>
          )}
          {loading ? (
            <Skeleton.Input active={loading} style={{ height: "18px", marginRight: "8px" }} />
          ) : (
            <p style={{ textAlign: "left" }}>{orders?.shipping_line?.title ?? "-"}</p>
          )}
          {loading ? (
            <Skeleton.Input active={loading} style={{ height: "18px", marginRight: "8px" }} />
          ) : (
            <p style={{ textAlign: "right" }}>
              <Money value={parseFloat(orders?.shipping_line?.price?.toString() ?? "0.00") * 100} />
            </p>
          )}

          {loading ? (
            <Skeleton.Input active={loading} style={{ height: "18px", marginRight: "8px" }} />
          ) : (
            <TaxDropdown open={taxation} setOpen={setTaxation} wrapperRef={wrapperRef}>
              <>Estimated tax</>
            </TaxDropdown>
          )}
          {!orders?.tax_exempt ? (
            loading ? (
              <Skeleton.Input active={loading} style={{ height: "18px", marginRight: "8px" }} />
            ) : (
              <Link
                style={{ textAlign: "left" }}
                to=""
                onClick={(e) => {
                  e.preventDefault();
                  orders?.id && setShowTax(true);
                }}
              >
                Show tax rates
              </Link>
            )
          ) : loading ? (
            <Skeleton.Input active={loading} style={{ height: "18px", marginRight: "8px" }} />
          ) : (
            <p style={{ textAlign: "left" }}>-</p>
          )}
          {loading ? (
            <Skeleton.Input active={loading} style={{ height: "18px", marginRight: "8px" }} />
          ) : (
            <p style={{ textAlign: "right" }}>
              <Money value={parseFloat(orders?.total_tax ?? "0.00") * 100} />
            </p>
          )}

          <p style={{ textAlign: "left", gridColumn: " span 2 / span 2", fontWeight: "bold" }}>
            Total
          </p>
          {loading ? (
            <Skeleton.Input active={loading} style={{ height: "18px", marginRight: "8px" }} />
          ) : (
            <p style={{ textAlign: "right", fontWeight: "bold" }}>
              <Money value={parseFloat(orders?.total_price ?? "0.00") * 100} />
            </p>
          )}
        </div>

        {/* <Divider /> */}
        {orders?.id && (
          <div
            style={{
              padding: "0px 8px",
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
            }}
          >
            {/* <Checkbox
              checked={payLater}
              disabled={loading}
              onChange={() => {
                setPayLater(!payLater);
              }}
            >
              Payment due later
            </Checkbox> */}

            {payLater && (
              <>
                <label style={{ textAlign: "left", marginTop: "1rem", marginBottom: "4px" }}>
                  Payment terms
                </label>
                <div style={{ display: "flex", justifyContent: "space-between", gap: "1.5rem" }}>
                  <Select
                    value={paymentDue}
                    direction="bottom"
                    style={{ width: "240px", textAlign: "left" }}
                    onChange={(e) => {
                      setPaymentDue(e);
                    }}
                    options={[
                      { value: "Due on Receipt", label: "Due on Receipt" },
                      { value: "Due on Fulfillment", label: "Due on Fulfillment" },
                      { value: "7", label: "Within 7 days" },
                      { value: "15", label: " Within 15 days" },
                      { value: "30", label: " Within 30 days" },
                      { value: "45", label: " Within 45 days" },
                      { value: "60", label: " Within 60 days" },
                      { value: "90", label: " Within 90 days" },
                      { value: "Fixed date", label: " Fixed date" },
                    ]}
                  />
                  {paymentDue === "Fixed date" && (
                    <DatePicker
                      disabledDate={(current) => {
                        let customDate = moment().format("YYYY-MM-DD");
                        return current && current < moment(customDate, "YYYY-MM-DD");
                      }}
                      value={moment(fixedDate)}
                      onChange={(e) => {
                        e && setFixedDate(e);
                      }}
                      format={"MMMM D, yyyy"}
                    />
                  )}
                </div>

                <p style={{ textAlign: "left", marginTop: "1rem" }}>
                  <b>{DueDateMessage}</b> You’ll be able to collect payment from the order page.
                </p>
              </>
            )}
          </div>
        )}
        {orders?.id && (
          <>
            {" "}
            <Divider />
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "end",
                gap: "1rem",
                padding: "0px 8px",
              }}
            >
              {payLater ? (
                <Button
                  type="primary"
                  style={{ display: "flex", alignItems: "center", gap: "4px" }}
                >
                  Create order
                </Button>
              ) : (
                <>
                  <Button
                    type="ghost"
                    loading={invoice}
                    onClick={() => {
                      setInvoice(true);
                      orders?.id &&
                        dispatch(sendInvoice(orders?.id)).then(() => {
                          setInvoice(false);
                          message.success(`invoice sent to ${orders.email}`);
                        });
                    }}
                  >
                    Send Invoice
                  </Button>
                  <Dropdown
                    overlay={
                      <Menu>
                        {" "}
                        <Menu.Item>
                          <div
                            onClick={() => {
                              setMarkAsPaid(true);
                            }}
                          >
                            Mark as paid
                          </div>
                        </Menu.Item>
                        <Menu.Item>
                          <a
                            onClick={() => {
                              setUnsaved(false);
                            }}
                            href={`${process.env.REACT_APP_SHOP_URL}/admin/draft_orders/${orders.id}`}
                            // eslint-disable-next-line react/jsx-no-target-blank
                            target="_blank"
                          >
                            Pay on shopify
                          </a>
                        </Menu.Item>
                      </Menu>
                    }
                    trigger={["click"]}
                  >
                    <Button
                      type="primary"
                      style={{ display: "flex", alignItems: "center", gap: "4px" }}
                    >
                      <p style={{ marginBottom: "0px" }}>Collect Payment</p> <IoIosArrowDown />
                    </Button>
                  </Dropdown>
                </>
              )}
            </div>
          </>
        )}
      </Card>
    </>
  );
};
