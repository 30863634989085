import { createAsyncThunk } from "@reduxjs/toolkit";
import { api, extractStandardResponseData } from "../../api";
import { endpoints } from "../../api/endpoints";
import { Customer } from "../../interfaces/Customer";
import { SearchDataContainer, axiosResHandle } from "../ordersSlice/ordersProductSlice";

export const searchCustomerWithTag = createAsyncThunk(
  "customers/searchWithTag",
  async (tag: string) =>
    api.get(endpoints.customersSearchWithTag, { params: { tag } }).then(extractStandardResponseData)
);
export const searchCustomer = createAsyncThunk("customers/search", async (q: string) =>
  api.get(endpoints.customersSearch, { params: { q } }).then(extractStandardResponseData)
);

export const deleteDeletedCustomer = createAsyncThunk(
  "customers/deleteCustomer",
  async ({ email }: Customer) => api.delete(`/customers/${email}`).then(extractStandardResponseData)
);

export type Address = {
  id?: number;
  customer_id?: number;
  first_name?: string;
  last_name?: string;
  company?: string | null;
  address1?: string;
  address2?: string;
  city?: string;
  province?: string;
  country?: string;
  zip?: string;
  phone?: string | null;
  name?: string;
  longitude?: string;
  latitude?: string;
  province_code?: string;
  country_code?: string;
  country_name?: string;
  default?: boolean;
};

interface EmailMarketingConsent {
  state: string;
  opt_in_level: string;
  consent_updated_at: string | null;
}

export type CustomerDetailsDataType = {
  id: number;
  email: string;
  accepts_marketing: boolean;
  created_at: string;
  updated_at: string;
  first_name: string;
  last_name: string;
  orders_count: number;
  state: string;
  total_spent: string;
  last_order_id: number;
  note: string | null;
  verified_email: boolean;
  multipass_identifier: string | null;
  tax_exempt: boolean;
  tags: string;
  last_order_name: string;
  currency: string;
  phone: string | null;
  addresses: Address[];
  accepts_marketing_updated_at: string;
  marketing_opt_in_level: string | null;
  tax_exemptions: any[];
  email_marketing_consent: EmailMarketingConsent;
  sms_marketing_consent: null;
  admin_graphql_api_id: string;
  default_address: Address;
};

export type CustomerDetailsType = {
  data: CustomerDetailsDataType;
};

const getDetailsService = async (params: { email: string }) => {
  return api.get<CustomerDetailsType>(`/customers/details/${params.email}`);
};

export const getDetails = createAsyncThunk(
  "customers/details",
  async (params: { email: string }, { rejectWithValue }) => {
    try {
      const res = await getDetailsService(params);
      return axiosResHandle(res);
    } catch (error) {
      throw rejectWithValue(error);
    }
  }
);

const editCustomerService = async (params: { id: number; email: string; phone: string }) => {
  return api.patch<CustomerDetailsType>(`/customers/contact/update`, { ...params });
};

export const editCustomer = createAsyncThunk(
  "customers/update",
  async (params: { id: number; email: string; phone: string }, { rejectWithValue }) => {
    try {
      const res = await editCustomerService(params);
      return axiosResHandle(res);
    } catch (error) {
      throw rejectWithValue(error);
    }
  }
);
