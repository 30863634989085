import React, { useEffect, useState } from "react";
import { Route, Switch, useHistory, useLocation, useParams, useRouteMatch } from "react-router-dom";
import { RootState, useAppDispatch } from "../../store/store";
import { Breadcrumb, Button, Card, Col, Collapse, Modal, PageHeader, Space } from "antd";
import { useSelector } from "react-redux";
import { deleteDraft, getOrder } from "../../store/draftSlice/draftSlice";
import { Order } from "../../interfaces/Order";
import { Descriptions, List, Table } from "antd/es";
import { DateTime } from "luxon";
import { Money } from "../../components/Money";
import { unwrapResult } from "@reduxjs/toolkit";
import _ from "lodash";
import { CancelOrderModal } from "../Orders/refunds/CancelOrderModal";
import { draftSelector } from "../../store/draftSlice/draftSlice";
import { draft, draftTable, LineItem } from "../../interfaces/Drafts";
import { clearPaginatedDraft } from "../../store/draftSlice/draftPaginationSlice";
import { draftStatus } from "../../enums/VerificationStatus";

const Panel = Collapse.Panel;

const panelStyle = {
  fontWeight: 500,
  fontSize: 16,
  padding: "4px 12px",
};

export const DraftView = () => {
  const history = useHistory();
  const location = useLocation();
  const { path } = useRouteMatch();
  const dispatch = useAppDispatch();
  // @ts-ignore
  const { id } = useParams();
  const invoice = location?.state?.state?.invoice;
  const draft = useSelector((s: RootState) => draftSelector.selectById(s, id)) as draftTable;
  const [order, setOrder] = useState<draft>();
  const [loading, setLoading] = useState(true);
  const [Delete, setDelete] = useState(false);
  const [taxLines, setTaxLines] = useState<
    {
      price: number;
      rate: number;
      title: string;
    }[]
  >([]);
  // const [availableRefunds, setAvailableRefunds] = useState<Refund>();

  useEffect(() => {
    let tempTax: {
      price: number;
      rate: number;
      title: string;
    }[] = [];
    order?.tax_lines?.map((t) => {
      tempTax.find((x) => x?.title === t.title) === undefined &&
        tempTax.push({
          price: order?.tax_lines?.reduce((accumulator, currentValue) => {
            return currentValue.title === t.title
              ? accumulator + parseFloat(currentValue.price)
              : accumulator;
          }, 0),
          rate: t.rate,
          title: t.title,
        });
    });
    setTaxLines(tempTax);
  }, [order]);

  useEffect(() => {
    setLoading(true);
    try {
      id &&
        dispatch(getOrder(id))
          .then(unwrapResult)
          .then((res) => {
            console.log({ res });
            setOrder(res);
            setLoading(false);
          });
    } catch (e) {
      setLoading(false);
    }
  }, [id]);

  useEffect(() => {
    console.log({ order }, [order]);
  });
  // const [isOrderRefundable, setIsOrderRefundable] = useState(false);

  const [cancelOrderSource, setCancelOrderSource] = useState<Order>();

  // useEffect(() => {
  //   dispatch(refundAvailability(order.id))
  //     .then(unwrapResult)
  //     .then((availableRefunds) => {
  //       setAvailableRefunds(availableRefunds);
  //       !availableRefunds.refundLineItems.length && setIsOrderRefundable(false);
  //     });
  // }, [order]);

  return (
    <Switch>
      <Route exact path={path}>
        <Breadcrumb>
          <Breadcrumb.Item key="dashboard">Dashboard</Breadcrumb.Item>
          <Breadcrumb.Item key="orders" onClick={() => history.push("/orders")}>
            Orders
          </Breadcrumb.Item>
          <Breadcrumb.Item key="order">{id}</Breadcrumb.Item>
        </Breadcrumb>

        {order ? (
          <>
            <Modal
              visible={Delete}
              destroyOnClose
              onCancel={() => setDelete(false)}
              onOk={() => {
                try {
                  order.id &&
                    dispatch(deleteDraft(order.id)).then(() => dispatch(clearPaginatedDraft()));
                  history.go(-1);
                } catch (e) {}
              }}
              okText="Yes"
            >
              Are you sure you want to delete?
            </Modal>
            <Col sm={24}>
              <PageHeader
                title={`${order.id} - (${order.name})`}
                className="site-page-header"
                onBack={() => history.go(-1)}
                extra={
                  <>
                    {/* <Button
                      type="primary"
                      onClick={() =>
                        Modal.confirm({
                          title: "Are you sure you want reset order properties?",
                          icon: <ExclamationCircleOutlined />,
                          onOk: () => dispatch(resetOrderProperties(order.id ?? 0)),
                        })
                      }
                    >
                      Reset Order Properties
                    </Button> */}
                    <Button
                      type="primary"
                      href={`${process.env.REACT_APP_SHOP_URL}/admin/draft_orders/${order.id}`}
                      target="_blank"
                    >
                      View In Shopify
                    </Button>

                    {order.status !== draftStatus.COMPELETED && (
                      <>
                        <Button type="primary" href={invoice} target="_blank">
                          View Invoice
                        </Button>
                        <Button
                          type="primary"
                          target="_blank"
                          onClick={() => {
                            // dispatch(setOrders(order));
                            history.push(`/orders/edit/${order.id}`);
                          }}
                        >
                          Edit Draft
                        </Button>
                        <Button
                          type="primary"
                          danger
                          onClick={() => {
                            setDelete(true);
                          }}
                        >
                          Delete Draft
                        </Button>
                      </>
                    )}
                  </>
                }
              />
            </Col>
            <Col sm={24} style={{ textAlign: "left" }}>
              <Card>
                <Collapse defaultActiveKey={["order-information"]}>
                  <Panel
                    header="Customer Information"
                    style={panelStyle}
                    key="customer-information"
                    showArrow={false}
                  >
                    <Descriptions style={{ padding: "1.5rem" }}>
                      <Descriptions.Item label="Customer Name">
                        {order?.customer?.first_name} {order?.customer?.last_name}
                      </Descriptions.Item>
                      <Descriptions.Item label="Email">{order?.email}</Descriptions.Item>
                      <Descriptions.Item label="Phone">
                        {order?.shipping_address?.phone ?? "No phone number"}
                      </Descriptions.Item>
                      <Descriptions.Item label="Address">
                        <>
                          {" "}
                          <p style={{ textAlign: "left", marginBottom: "0px" }}>
                            <>{order?.customer?.default_address?.address1} </>
                          </p>
                          <p style={{ textAlign: "left", marginBottom: "0px" }}>
                            {order?.customer?.default_address?.address2}{" "}
                          </p>
                          <p style={{ textAlign: "left", marginBottom: "0px" }}>
                            {order?.customer?.default_address?.city &&
                              `${order?.customer.default_address?.city},`}{" "}
                            {order?.customer?.default_address?.province}{" "}
                          </p>
                          <p style={{ textAlign: "left" }}>
                            {order?.customer?.default_address?.country}{" "}
                            {order?.customer?.default_address?.country &&
                              order?.customer?.default_address?.zip &&
                              " - "}{" "}
                            {order?.customer?.default_address?.zip}{" "}
                          </p>
                        </>
                      </Descriptions.Item>
                    </Descriptions>
                  </Panel>
                  <Panel
                    header="Order Information"
                    key="order-information"
                    style={panelStyle}
                    showArrow={false}
                  >
                    <Space direction="vertical">
                      <Descriptions style={{ padding: "1.5rem" }}>
                        <Descriptions.Item label="Draft Id">{order.id}</Descriptions.Item>
                        <Descriptions.Item label="Billing Address">
                          <>
                            {" "}
                            <p style={{ textAlign: "left", marginBottom: "0px" }}>
                              {order?.billing_address?.address1}{" "}
                            </p>
                            <p style={{ textAlign: "left", marginBottom: "0px" }}>
                              {order?.billing_address?.address2}{" "}
                            </p>
                            <p style={{ textAlign: "left", marginBottom: "0px" }}>
                              {order?.billing_address?.city && `${order?.billing_address.city},`}{" "}
                              {order?.billing_address?.province}{" "}
                            </p>
                            <p style={{ textAlign: "left" }}>
                              {order?.billing_address?.country}{" "}
                              {order?.billing_address?.country &&
                                order?.billing_address?.zip &&
                                " - "}{" "}
                              {order?.billing_address?.zip}{" "}
                            </p>
                          </>
                        </Descriptions.Item>

                        <Descriptions.Item label="Created at">
                          {DateTime.fromISO(order.created_at).toLocaleString(DateTime.DATETIME_MED)}
                        </Descriptions.Item>
                        <Descriptions.Item label="Note">{order?.note ?? ""}</Descriptions.Item>
                      </Descriptions>

                      {order.line_items.length > 0 && (
                        <Card title="Line Items">
                          <List
                            itemLayout="horizontal"
                            dataSource={order.line_items}
                            renderItem={(li: LineItem) => (
                              <List.Item>
                                <Descriptions style={{ padding: "1.5rem" }} title={li?.title}>
                                  <Descriptions.Item label="SKU">{li?.sku}</Descriptions.Item>
                                  <Descriptions.Item label="Variant">
                                    {li?.variant_title}
                                  </Descriptions.Item>
                                  <Descriptions.Item label="Quantity">
                                    {li?.quantity}
                                  </Descriptions.Item>

                                  <Descriptions.Item
                                    label={li?.applied_discount ? "Original Price" : "Price"}
                                  >
                                    <Money value={parseInt(li?.price) * 100} />
                                  </Descriptions.Item>
                                  {li?.applied_discount && (
                                    <>
                                      <Descriptions.Item label="Discount">
                                        <Money
                                          value={parseFloat(li?.applied_discount.amount) * 100}
                                        />
                                      </Descriptions.Item>
                                      <Descriptions.Item label="Discounted Price">
                                        <Money
                                          value={
                                            (parseInt(li?.price) -
                                              parseFloat(li?.applied_discount.amount)) *
                                            100
                                          }
                                        />
                                      </Descriptions.Item>
                                      {li.applied_discount.description && (
                                        <Descriptions.Item label="Reason for Discount">
                                          {li.applied_discount.description}
                                        </Descriptions.Item>
                                      )}
                                    </>
                                  )}
                                </Descriptions>
                              </List.Item>
                            )}
                          />
                        </Card>
                      )}
                    </Space>
                  </Panel>
                  <Panel header="Payment Information" key="3" style={panelStyle} showArrow={false}>
                    <Descriptions style={{ padding: "1.5rem" }}>
                      <Descriptions.Item label="Sub Total">
                        <Money
                          value={
                            (parseFloat(order.subtotal_price ?? "0") +
                              parseFloat(order.applied_discount?.amount ?? "0")) *
                            100
                          }
                        />
                      </Descriptions.Item>
                      <Descriptions.Item label="Total Tax">
                        <Money value={parseFloat(order.total_tax ?? "0") * 100} />
                      </Descriptions.Item>
                      <Descriptions.Item label="Discount">
                        <Money value={parseFloat(order.applied_discount?.amount ?? "0") * 100} />
                      </Descriptions.Item>
                      <Descriptions.Item label="Total">
                        <Money value={parseFloat(order.total_price ?? "0") * 100} />
                      </Descriptions.Item>
                    </Descriptions>

                    <Card title="Tax Breakdown">
                      <Table
                        pagination={false}
                        dataSource={taxLines}
                        rowKey="id"
                        summary={() => (
                          <>
                            <Table.Summary.Row>
                              <Table.Summary.Cell index={0}>
                                <strong>Total Tax</strong>
                              </Table.Summary.Cell>
                              <Table.Summary.Cell index={1} />
                              <Table.Summary.Cell index={2}>
                                <strong>
                                  <Money value={parseFloat(order?.total_tax) * 100} />
                                </strong>
                              </Table.Summary.Cell>
                            </Table.Summary.Row>
                          </>
                        )}
                      >
                        <Table.Column title="Title" dataIndex={"title"} />
                        <Table.Column
                          title="Rate"
                          dataIndex={"rate"}
                          render={(val) => <div>{`${val * 100}%`}</div>}
                        />
                        <Table.Column
                          title="Price"
                          dataIndex={"price"}
                          render={(val) => <Money value={val * 100} />}
                        />
                      </Table>
                    </Card>
                  </Panel>
                </Collapse>
              </Card>
            </Col>
            {cancelOrderSource && (
              <CancelOrderModal
                order={cancelOrderSource}
                onClose={() => {
                  setCancelOrderSource(undefined);
                  try {
                    dispatch(getOrder(id));
                  } catch (e) {}
                }}
              />
            )}
          </>
        ) : (
          <>
            <Col sm={24}>
              <h3>Draft not found</h3>
            </Col>
          </>
        )}
      </Route>
    </Switch>
  );
};
