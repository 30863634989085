import { createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { FinancialStatus } from "../../enums/FinancialStatus";
import { VerificationStatus } from "../../enums/VerificationStatus";
import { api, extractStandardResponseData } from "../../api";
import { RootState } from "../store";
import { Refund } from "../../interfaces/Refund";
import { draft, draftTable } from "../../interfaces/Drafts";
import { axiosResHandle } from "../ordersSlice/ordersProductSlice";
import { Address } from "../customersSlice/customersSlice";

export type createOrderType = {
  line_items: {
    variant_id: number;
    quantity: number;
  }[];
  customer: {
    id: number;
  };
  note?: string;
  use_customer_default_address: boolean;
  billing_address?: Address;
  shipping_address?: Address;
};

export const draftAdapter = createEntityAdapter<draft | draftTable>();

export const draftIndex = createAsyncThunk(
  "draft/index",
  async (filters: { page?: number; searchTerm?: string }) =>
    api.get("/draft_order/get/all", { params: filters }).then((res) => res.data)
);

export const getOrder = createAsyncThunk("draft/getOrder", (orderId: number) =>
  api.get(`/draft_order/get/${orderId}`).then(extractStandardResponseData)
);

export const refundAvailability = createAsyncThunk(
  "draft/refundAvailability",
  async (orderId: number) =>
    api.get(`/draft/${orderId}/refunds/availability`).then(extractStandardResponseData)
);

export const calculateRefundTransactions = createAsyncThunk(
  "draft/calculateRefundTransactions",
  async (refund: Refund) =>
    api.post(`/draft/${refund.orderId}/refunds/calculate`, refund).then(extractStandardResponseData)
);

export const createRefund = createAsyncThunk("draft/createRefund", async (refund: Refund) =>
  api.post(`/draft/${refund.orderId}/refunds`, refund).then(extractStandardResponseData)
);

export const getRawOrder = createAsyncThunk("draft/gatRawOrder", (orderId: number) =>
  api.get(`/draft/${orderId}/raw`).then(extractStandardResponseData)
);

export const sendInvoice = createAsyncThunk("draft/email", (orderId: number) =>
  api.post(`/draft_order/sendemail/${orderId}`).then(extractStandardResponseData)
);

export const compeleteOrder = createAsyncThunk(
  "draft/compelete",
  ({ orderId, payment_pending }: { orderId: number; payment_pending: boolean }) =>
    api
      .patch(`/draft_order/sendemail/${orderId}`, { payment_pending })
      .then(extractStandardResponseData)
);

export const cancelOrder = createAsyncThunk(
  "draft/cancelOrder",
  async (payload: { notify: boolean; restock: boolean; orderId: number }) => {
    return api.post(`/draft/${payload.orderId}/cancel`, payload);
  }
);

export const resetOrderProperties = createAsyncThunk(
  "draft/resetOrderProperties",
  async (orderId: number) => {
    return api.post(`/draft/${orderId}/reset_properties`);
  }
);

const createOrderService = async (params: createOrderType) => {
  return api.post<{ data: draft }>(`/draft_order/create`, {
    ...params,
  });
};

export const createOrder = createAsyncThunk(
  "draft_order/create",
  async (params: createOrderType, { rejectWithValue }) => {
    try {
      const res = await createOrderService(params);
      return axiosResHandle(res);
    } catch (error) {
      throw rejectWithValue(error);
      
    }
  }
);

const editOrderService = async (params: { id: number; data: any }) => {
  return api.patch<{ data: any }>(`/draft_order/update/${params.id}`, {
    ...params.data,
  });
};

export const editOrder = createAsyncThunk(
  "draft_order/update",
  async (params: { id: number; data: any }, { rejectWithValue }) => {
    try {
      const res = await editOrderService(params);
      return axiosResHandle(res);
    } catch (error) {
      throw rejectWithValue(error);
    }
  }
);

const deleteOrderService = async (id: number) => {
  return api.delete<{ message: "string" }>(`/draft_order/delete/${id}`);
};

export const deleteDraft = createAsyncThunk(
  "draft_order/delete",
  async (id: number, { rejectWithValue }) => {
    try {
      const res = await deleteOrderService(id);
      return axiosResHandle(res);
    } catch (error) {
      throw rejectWithValue(error);
    }
  }
);

const savedraftService = async (params: { id: number; paid: boolean }) => {
  return api.patch<{ data: any }>(`/draft_order/complete/${params.id}`, {
    payment_pending: params.paid,
  });
};

export const saveDraft = createAsyncThunk(
  "draft_order/complete",
  async (params: { id: number; paid: boolean }, { rejectWithValue }) => {
    try {
      const res = await savedraftService(params);
      return axiosResHandle(res);
    } catch (error) {
      throw rejectWithValue(error);
    }
  }
);

export const draftSlice = createSlice({
  name: "draft",
  initialState: draftAdapter.getInitialState(),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(draftIndex.fulfilled, (state, { payload: { data } }) => {
      return draftAdapter.upsertMany(state, data);
    });
    builder.addCase(getOrder.fulfilled, (state, { payload }) => {
      return draftAdapter.upsertOne(state, payload);
    });
    
  },
});

export const draftSelector = draftAdapter.getSelectors((s: RootState) => s.draft);
