import { Button, Modal } from "antd";
import React, { useEffect, useState } from "react";
import { Money } from "../../../../../components/Money";
import { useAppSelector } from "../../../../../store/store";

export const TaxRateModal = ({ open, setOpen }: { open: boolean; setOpen: Function }) => {
  const taxes = useAppSelector((state) => state.newdraft.orders?.tax_lines);
  const total = useAppSelector((state) => state.newdraft.orders?.total_tax);
  const [tax, setTax] = useState<
    {
      price: number;
      rate: number;
      title: string;
    }[]
  >([]);

  useEffect(() => {
    let tempTax: {
      price: number;
      rate: number;
      title: string;
    }[] = [];
    taxes?.map((t) => {
      tempTax.find((x) => x?.title === t.title) === undefined &&
        tempTax.push({
          price: taxes.reduce((accumulator, currentValue) => {
            return currentValue.title === t.title
              ? accumulator + parseFloat(currentValue.price)
              : accumulator;
          }, 0),
          rate: t.rate,
          title: t.title,
        });
    });
    setTax(tempTax);
  }, [taxes]);

  useEffect(() => {
    console.log({ tax });
  }, [tax]);

  return (
    <Modal
      destroyOnClose
      visible={open}
      onCancel={() => {
        setOpen(false);
      }}
      footer={<Button onClick={() => setOpen(false)}>Close</Button>}
      title={"Tax rates"}
    >
      {tax?.map((x) => (
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <p>
            {x.title} ({x.rate * 100}%)
          </p>
          <Money value={x.price * 100} />
        </div>
      ))}
      <div style={{ display: "flex", justifyContent: "space-between", fontWeight: "bold" }}>
        <p>Total Tax</p>
        <Money value={parseFloat(total ?? "0") * 100} />
      </div>
    </Modal>
  );
};
